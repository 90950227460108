import { sha256 } from 'js-sha256'
import MD5 from 'md5'
import { debuggerLog } from '.'

const paramsKey = ['cardNumber', 'expireMonth', 'expireYear', 'cvv']
//加密后的key
//!!! 按照 卡号，月份，年份，cvv 的排序
const keyTokey = ['cardNoHash', 'cardExpireMonthHash', 'cardExpireYearHash', 'cvvHash']
// 对加密值进行签名后的key
//!!! 按照 卡号，月份，年份，cvv 的排序
const keyToSign = ['cardNoCheck', 'cardExpireMonthCheck', 'cardExpireYearCheck', 'cvvCheck']

interface IEncrptParams {
  sourceData: Record<string, any>
  publicKeyObj: Record<string, any>
  encryptObj: {
    encrypt?: (val: string) => string
  }
}

// cvv格式校验
export default function encryptParams({
  sourceData = {},
  publicKeyObj = {},
  encryptObj = {},
}: IEncrptParams) {
  const obj: any = { ...sourceData }
  let paymentHash = ''
  let safeAccountHash = ''
  debuggerLog('encryptParams>>sourceData>>>', obj)
  paramsKey.some((key, index) => {
    const hashKey = keyTokey[index]
    const signKey = keyToSign[index]
    const val = '' + obj[key]

    //月份补零，比如 06
    if (key == 'expireMonth' && +val < 10) {
      paymentHash += '0' + (+val)
      //年份补全，比如 2023
    } else if (key == 'expireYear' && val.length == 2) {
      paymentHash += '20' + (+val)
    } else {
      paymentHash += val
    }

    //二次加密值（给卡号生成唯一id）
    if (key === 'cardNumber' && val) {
      const accountHash = sha256(val)
      const saltPlaintext = accountHash.substring(2, 14)
      const salt = sha256(saltPlaintext)
      safeAccountHash = sha256(salt + accountHash)
      obj.safeAccountHash = safeAccountHash
    }

    if (encryptObj.encrypt?.(val)) {
      obj[hashKey] = encryptObj.encrypt(val)
      obj[key] = ''
      try {
        obj[signKey] = MD5(publicKeyObj.pub_id + obj[hashKey] + publicKeyObj.pub_id)
      } catch (e) {
        console.log('md5 fail')
        obj[signKey] = ''
      }
    } else {
      return true
    }
  })

  //上送是否选中过期年月，固定为FALSE
  obj.isExpiredCard = 'FALSE'

  if (paymentHash) {
    obj.paymentHash = sha256(paymentHash)
  }
  return obj
}
