import { PRODUCT_TYPE, CHECKOUT_TYPE } from '../../../types'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
// import { type Trade_PayLibs } from '@shein-aidc/types-trade'
import { type SILogger, type SIMetric } from '@shein-aidc/monitor-core'

export type MetricReport = {
  scene: string;
  /**
   * 上报场景 abnormal: 异常场景 business: 业务场景 默认为 abnormal 用于区分metric_name
   *
   * @type {('abnormal' | 'business')}
   */
  report_scene?: 'abnormal' | 'business'
  extraParams?: Record<string, any>;
  extraTags?: {
    payment_code?: string;
    status_code?: number | string;
    failure_type?: 'web' | // 前端代码逻辑报错
    'sdk' | // sdk报错
    'api' // 接口报错
  }
}

export class MonitorReport {

  private static appConfigs: ReturnType<typeof useAppConfigs>

  private static commonParams: {
    productType?: PRODUCT_TYPE,
    checkoutType: CHECKOUT_TYPE,
    businessServiceType: string,
    currentUrl: string,
    subSite: string,
    sysName: string,
    useInline?: '0' | '1',
  } = {
    businessServiceType: 'error_payment_failed',
    productType: undefined,
    checkoutType: CHECKOUT_TYPE.NORMAL,
    currentUrl: '',
    subSite: '',
    sysName: '',
  }

  private static getMonitorManager: () => {
    SILogger: typeof SILogger;
    SIMetric: typeof SIMetric;
  }

  private static reportTool: {
    SILogger: typeof SILogger;
    SIMetric: typeof SIMetric;
  }

  public static get metricReportTool() {
    if (!this.reportTool) {
      this.reportTool = this.getMonitorManager?.()
    }
    return this.reportTool?.SIMetric
  }

  public static get loggerReportTool() {
    if (!this.reportTool) {
      this.reportTool = this.getMonitorManager?.()
    }
    return this.reportTool?.SILogger
  }

  static initMonitor = ({
    getMonitorManager,
    productType,
    checkoutType,
    useInline,
  }: {
    getMonitorManager: () => {
      SILogger: typeof SILogger;
      SIMetric: typeof SIMetric;
    };
    productType?: PRODUCT_TYPE;
    checkoutType: CHECKOUT_TYPE;
    useInline: boolean;
  }) => {
    this.appConfigs = useAppConfigs()
    this.getMonitorManager = getMonitorManager
    this.commonParams.productType = productType
    this.commonParams.checkoutType = checkoutType
    this.commonParams.useInline = useInline ? '1' : '0'
    this.commonParams.currentUrl = window.location.href
    this.commonParams.subSite = this.appConfigs.$envs.siteUID
    this.commonParams.sysName = this.appConfigs.$envs.project
  }

  static updateCommonParams = (params: Partial<typeof MonitorReport.commonParams>) => {
    this.commonParams = {
      ...this.commonParams,
      ...params,
    }
  }

  // public static adsLog = (log: string) => {
  //   try {
  //     this.loggerReportTool?.logDebug({
  //       message: log,
  //       tag: 'ads',
  //     })
  //   } catch (e) {
  //     console.error('adsLog error:', e)
  //   }
  // }

  public static abnormalMetric = (metric: MetricReport) => {
    try {
      this.metricReportTool?.metricCount?.({
        metric_name: 'web_payment_error_total',
        tags: {
          report_source: 'bs-libs',
          failure_type: metric.extraTags?.failure_type,
          product_type: this.commonParams.productType,
          checkout_type: this.commonParams.checkoutType,
          business_service_type: this.commonParams.businessServiceType,
          error_scene: metric.scene,
          sub_site: this.commonParams.subSite,
          status_code: String(metric.extraTags?.status_code || ''),
          payment_method: metric.extraTags?.payment_code,
        },
        params: {
          url: this.commonParams.currentUrl,
          sys_name: this.commonParams.sysName,
          use_inline: this.commonParams.useInline,
          ...(metric.extraParams || {}),
        },
      })
    } catch (e) {
      console.error('metricReport error:', e)
    }
  }

  public static businessMetric = (metric: MetricReport) => {
    try {
      this.metricReportTool?.metricCount?.({
        metric_name: 'web_payment_business',
        tags: {
          report_source: 'bs-libs',
          status_code: String(metric.extraTags?.status_code || ''),
          product_type: this.commonParams.productType,
          checkout_type: this.commonParams.checkoutType,
          business_scene: metric.scene,
          sub_site: this.commonParams.subSite,
          payment_method: metric.extraTags?.payment_code,
        },
        params: {
          url: this.commonParams.currentUrl,
          sys_name: this.commonParams.sysName,
          use_inline: this.commonParams.useInline,
          ...(metric.extraParams || {}),
        },
      })
    } catch (e) {
      console.error('metricReport error:', e)
    }
  }

  // static loggerReport = (log: Trade_PayLibs.LoggerReport) => {
  //   // this.reportTool.SILogger
  //   this.reportTool.SILogger?.logDebug({})
  // }

  /**
   * 上报端侧日志到grafana及sls
   *
   * @static
   * @param {MetricReport} metric
   * @memberof MonitorReport
   */
  static metric: (metric: MetricReport) => void
}

export class MonitorReportAbnormal extends MonitorReport {
  static metric = (metric: Omit<MetricReport, 'report_scene'>) => {
    const report_scene: MetricReport['report_scene'] = 'abnormal'
    super.abnormalMetric(Object.assign(metric, { report_scene }))
  }
}

export class MonitorReportBusiness extends MonitorReport {
  static metric = (metric: Omit<MetricReport, 'report_scene'>) => {
    const report_scene: MetricReport['report_scene'] = 'business'
    super.businessMetric(Object.assign(metric, { report_scene }))
  }
}

