import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

/**
 * @description 非卡支付全链路路由 hook
 */

export interface OrderType {
  billno: string;
  payment_method: string;
  bin?: string;
  tokenId?: string;
  [key: string]: any;
}

export interface PreCardRoutingParams {
  routeCode: string;
  relationBillno?: string;
  billno?: string;
  bin?: string;
  tokenId?: string;
  cardTypeSupportList?: string[];
}

export class NoCardRoutepayPreRouting {

  private static syncPreCardRoutingApi ({ params, appConfigs }) {
    let result = {}
    appConfigs.$schttpSync({
      baseURL: appConfigs.$envs?.langPath,
      url: '/api/checkout/payPreCardRouting/get',
      params,
      success: (res) => {
        result = res?.data || res || {}
      },
    })
    return result
  }

  private static async asyncPreCardRoutingApi ({ params, appConfigs }) {
    const result = await appConfigs.$schttp({
      baseURL: appConfigs.$envs?.langPath,
      url: '/api/checkout/payPreCardRouting/get',
      params,
    })

    return result.data || result
  }

  static async payPreCardRoutingApi ({ params, useSync }) {
    const appConfigs = useAppConfigs()
    const result = useSync ? this.syncPreCardRoutingApi({ params, appConfigs }) : await this.asyncPreCardRoutingApi({ params, appConfigs })
    return result
  }

}
