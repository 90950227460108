import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { loadChannelSdk } from '../../channel/initSdk'
import { debuggerLog } from '../../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { ThreeDSecureManager } from '../helpers/ThreeDSecureManager'
import Adyen3ds from '../../channel/adyen'
import PayPalCard from '../../channel/ppCard'
import { MonitorReportAbnormal, MonitorReportBusiness } from '../helpers/MonitorReport'
import { PAYMENT_SCENE_TYPE } from '../../../types'
import { commonSendDdc, redirectUsingForm } from '../../utils/ddcAnd3ds'
import { CreateNewWindow } from '../helpers/CreateNewWindow'

export class FrontPayManager extends BasicPayManager implements AbstractMethods {

  private adyenInstance

  private ppCardInstance

  private unifiedPayChallengeInfo = {
    gatewayPayNo: '',
    paymentCode: '',
    independThreedTraceID: '',
  }

  constructor(params: BasicPayManagerConstructor) {
    super(params)

    this.initThreeDsInstance()
  }

  static initChannelSDK = () => {
    Promise.all([
      loadChannelSdk.initAdyenSdk?.(),
      loadChannelSdk.initPPCardSdk?.(),
    ])
  }

  private async initThreeDsInstance () {
    const adyenKey = await this.bsUtils?.getAdyenKey?.()
    this.adyenInstance = new Adyen3ds({
      completeHandle: this.handleThreeDSComplete.bind(this),
      errorHandle: this.handleThreeDSError.bind(this),
      originKey: adyenKey || '',
      env: this.bsPayConfig.environment === 'TEST' ? 'test' : 'live',
    })
    this.ppCardInstance = new PayPalCard({
      completeHandle: this.handleThreeDSComplete.bind(this),
      errorHandle: this.handleThreeDSError.bind(this),
    })
  }

  private handleLtspcPayRender = ({ res }: {
    res: Trade_PayLibs.UnifiedPayRes;
  }) => {
    debuggerLog('handleLtspcPayRender===', res)
    const messageChangeCallback = ({ status, result }: { status: string, result: Record<string, any> }) => {
      if (status === 'success') {
        debuggerLog('handleLtspcPayRender===messageChangeCallback===success', result)
        ThreeDSecureManager?.sdkBridgeActions.handleThreeDSecureModal({
          visible: false,
          level: '1.0',
        })
      }
      if (_handlePayRenderMessageChange) {
        window.removeEventListener('message', _handlePayRenderMessageChange, false)
      }
    }
    const _handlePayRenderMessageChange = ThreeDSecureManager?.createMessageEventChange({
      callback: messageChangeCallback,
    })
    const {
      paramList,
      paymentCode: paymentMethod,
      isIndepenDsThreed,
      independThreedTraceID = '',
      independThreeDsChannel,
      gatewayPayNo = '',
      paymentCode = '',
      actionUrl,
    } = res?.info || {}

    // 触发独立3ds挑战
    this.unifiedPayChallengeInfo.independThreedTraceID = isIndepenDsThreed == 1 && independThreedTraceID ? independThreedTraceID : ''

    // 通用ddc收集
    if (independThreeDsChannel === 'cybersource' && isIndepenDsThreed === 1 && paramList.challengeFlag != '1') {
      this.paymentLoadingAction?.({ show: true })
      this.unifiedPayChallengeInfo.gatewayPayNo = gatewayPayNo
      this.unifiedPayChallengeInfo.paymentCode = paymentCode
      const { redirectUrl, method, parameters = '{}' } = paramList
      commonSendDdc({
        action: redirectUrl,
        method,
        params: JSON.parse(parameters),
        cb: () => {
          this.paymentLoadingAction?.({ show: false })
          this.handleThreeDSComplete({ paramList })
        },
      })
      return true
    }

    // cybersource 3ds挑战
    if (independThreeDsChannel === 'cybersource' && paramList.challengeFlag == '1') {
      redirectUsingForm({
        action: paramList.redirectUrl,
        method: paramList.method,
        params: paramList.parameters,
      })
      return true
    }

    if (paymentMethod == 'worldpay-cardjs3ds') {
      const { jwt, md } = paramList
      if (jwt && md) {
        paramList.JWT = jwt
        paramList.MD = md
        delete paramList.jwt
        delete paramList.md
        ThreeDSecureManager?.sdkBridgeActions.handleThreeDSecureModal({
          visible: true,
          level: '1.0',
          paramList,
          actionUrl,
          paymentCode: paymentMethod,
        })
        if (_handlePayRenderMessageChange) {
          window.addEventListener('message', _handlePayRenderMessageChange, false)
        }
        MonitorReportBusiness.metric({
          scene: 'front_token_start_worldpaycardjs_3ds1',
          extraTags: {
            payment_code: this.unifiedPayParams?.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams?.billno,
            paymentMethod,
          },
        })
        return
      }
    }
    if (isIndepenDsThreed == 1 && paramList.TermUrl) {
      // 触发独立3ds 1.0 挑战
      const termurl = paramList.TermUrl
      paramList.TermUrl =
        termurl.indexOf('?') > -1
          ? `${termurl}&independThreedTraceID=${independThreedTraceID}`
          : `${termurl}?independThreedTraceID=${independThreedTraceID}`
    }

    ThreeDSecureManager?.makeForm?.({ params: paramList, action: actionUrl, method: 'post' })

    MonitorReportBusiness.metric({
      scene: 'front_token_start_make_form_3ds1',
      extraTags: {
        payment_code: this.unifiedPayParams?.paymentCode,
      },
      extraParams: {
        billno: this.unifiedPayParams?.billno,
        paymentMethod,
      },
    })
  }

  private handleLtspcPayChallenge = ({ res }: { res: Trade_PayLibs.UnifiedPayRes }) => {
    debuggerLog('handleLtspcPayChallenge===', res)
    const { paramList = {} } = res?.info || {}

    if (
      paramList.fingerprintToken ||
      paramList?.['threeds2.fingerprintToken']
    ) {
      this.unifiedPayChallengeInfo.gatewayPayNo = res.info.gatewayPayNo
      this.unifiedPayChallengeInfo.paymentCode = res.info.paymentCode
      let threeDs2Id = ''
      ThreeDSecureManager?.sdkBridgeActions.handleThreeDSecureModal({
        visible: true,
        level: '2.0',
        action: (info) => {
          debuggerLog('threeDs2Id===info===', info)
          threeDs2Id = info?.threeDs2Id || ''
        },
      })
      this.adyenInstance?.init3DSIS?.({
        ftoken: paramList.fingerprintToken || paramList?.['threeds2.fingerprintToken'],
        mountId: `#${threeDs2Id}`,
      })
      MonitorReportBusiness.metric({
        scene: 'front_token_start_init_3ds2',
        extraTags: {
          payment_code: this.unifiedPayParams?.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams?.billno,
          paymentMethod: res.info.paymentCode,
        },
      })
    } else if (
      paramList.challengeToken ||
      paramList?.['threeds2.challengeToken']
    ) {
      debuggerLog('challengeToken', paramList.challengeToken)
      this.unifiedPayChallengeInfo.gatewayPayNo = res.info.gatewayPayNo
      this.unifiedPayChallengeInfo.paymentCode = res.info.paymentCode
      let threeDs2Id = ''
      ThreeDSecureManager.sdkBridgeActions?.handleThreeDSecureModal?.({
        visible: true,
        level: '2.0',
        action: (info) => {
          threeDs2Id = info?.threeDs2Id || ''
         },
      })
      debuggerLog('threeDs2Id===', threeDs2Id)
      this.adyenInstance?.initChallenge?.({
        ctoken: paramList.challengeToken || paramList?.['threeds2.challengeToken'],
        mountId: `#${threeDs2Id}`,
      })
      MonitorReportBusiness.metric({
        scene: 'front_token_start_init_challenge2',
        extraTags: {
          payment_code: this.unifiedPayParams?.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams?.billno,
          paymentMethod: res.info.paymentCode,
        },
      })
    } else if (res?.info.paymentCode == 'PayPal-card') {
      this.unifiedPayChallengeInfo.gatewayPayNo = res.info.gatewayPayNo
      this.unifiedPayChallengeInfo.paymentCode = res.info.paymentCode
      this.ppCardInstance?.initPaypalDropIn?.({
        paramList,
        totalPriceAmount: this.payData?.channelExtraInfo?.orderAmount || '',
        email: this.payData?.channelExtraInfo?.email || '',
      })
    }
  }

  private handleThreeDSComplete({ step, paramList, ...specialData } = {} as any) {
    debuggerLog('handleThreeDSComplete--', { step, paramList, ...specialData })
    if (step === 'ChallengeShopper') {
      ThreeDSecureManager.sdkBridgeActions?.handleThreeDSecureModal?.({
        visible: false,
        level: '2.0',
      })
    }

    const data = {
      billno: this.unifiedPayParams.billno || '',
      gatewayPayNo: this.unifiedPayChallengeInfo.gatewayPayNo,
      paymentCode: this.unifiedPayChallengeInfo.paymentCode,
      independThreedTraceID: this.unifiedPayChallengeInfo.independThreedTraceID || '',
      verificationStep: step || '',
      verificationResult: specialData?.verificationResult || '',
      independThreedsVerifyInfo: specialData?.independThreedsVerifyInfo || '',
      threeDVerifyResult: specialData?.threeDVerifyResult || '',
      neurPayId: this.kafkaReport.kafkaInfo?.neurPayId,
      neurStep: this.kafkaReport.kafkaInfo?.neurStep + 1,
      paramList: '',
    }

    // 整个paramList传给后端，后端自己挑选需要的参数
    if (paramList) {
      data.paramList = typeof paramList === 'string' ? paramList : JSON.stringify(paramList)
    }

    this.handleUnifiedPayComplete(data).then(result => {
        debuggerLog('postTokenUnPayComplete--result-----', result)
        // const { status } = unHandleResult(result) || {}
        this.handleFrontPayResult(result)
      }).catch(err => {
        const func = this.onFail || this.onError
        func?.(err)
        this.kafkaReport?.sendData?.({ resStatus: err?.status || '-1' })
      })
  }

  private handleThreeDSError(err) {
    debuggerLog('handleThreeDSError--', err)
    ThreeDSecureManager.sdkBridgeActions?.handleThreeDSecureModal?.({
      visible: false,
      level: '2.0',
    })
    MonitorReportAbnormal.metric({
      scene: 'front_token_3ds_error',
      extraTags: {
        payment_code: this.unifiedPayParams?.paymentCode,
      },
      extraParams: {
        billno: this.unifiedPayParams?.billno,
        error_stack: JSON.stringify(err && typeof err === 'object' ? err || {} : {}),
        paymentMethod: this.unifiedPayChallengeInfo.paymentCode,
      },
    })
    const func = this.onFail || this.onError
    func?.(err)
    return err
  }

  private handleRedirectAction = (result: Trade_PayLibs.UnifiedPayRes) => {
    const actionUrl = result?.info?.actionUrl || ''
    debuggerLog('handleRedirectAction===', result, actionUrl)
    if (actionUrl) {
      // window.location.href = actionUrl
      if (this.bsPayConfig?.useInline) {
        CreateNewWindow.continueRedirect({ actionUrl })
      } else {
        window.location.href = actionUrl
      }
    } else {
      this.onError?.(result as Trade_PayLibs.UnifiedPayRes, {
        paymentScene: PAYMENT_SCENE_TYPE.FRONT_PAY,
        mergeQuery: {
          code: result?.info?.error_code || '',
          paymentCode: result?.info?.paymentCode || '',
        },
      })
      CreateNewWindow.destroy?.()
    }
  }

  private handleFrontPayResult = (info: Trade_PayLibs.BasicUnHandleResult) => {
    debuggerLog('FrontTokenPayManager===handleFrontPayResult', info)
    const { status, result } = info || {}
    if (status !== 'continue') {
      if (status === 'success' || status === 'pending') {
        this.onSuccess?.(result as Trade_PayLibs.UnifiedPayRes, {
          paymentScene: PAYMENT_SCENE_TYPE.FRONT_PAY,
          mergeQuery: {
            gatewayPayNo: result?.info?.gatewayPayNo || '',
          },
        })
      } else if (status === 'error') {
        this.onError?.(result as Trade_PayLibs.UnifiedPayRes, {
          paymentScene: PAYMENT_SCENE_TYPE.FRONT_PAY,
          mergeQuery: {
            code: result?.info?.error_code || '',
            paymentCode: result?.info?.paymentCode || '',
          },
        })
      } else if (status === 'catchError') {
        this.onFail?.(result as any)
      } else if (status === 'cancel') {
        this.onCancel?.()
      }
    } else {
      // 先清空，避免独立3ds失败兜底切换成渠道3ds时传入错误的值
      this.unifiedPayChallengeInfo.independThreedTraceID = ''

      const { action, paramList } = result?.info || {}
      if (action === 'render' && paramList) {
        this.handleLtspcPayRender({ res: result as Trade_PayLibs.UnifiedPayRes })
      } else if (action === 'challenge' && paramList) {
        this.handleLtspcPayChallenge({ res: result as Trade_PayLibs.UnifiedPayRes })
      } else if (action === 'redirect') {
        this.handleRedirectAction(result as Trade_PayLibs.UnifiedPayRes)
      } else {
        this.onCancel?.()
      }
    }
  }

  public createPayment = async (): Promise<any> => {
    debuggerLog('FrontPayManager===createPayment')
    if (this.unifiedPayParams.tokenId) {
      debuggerLog('FrontTokenPayManager===createPayment===卡token支付场景=====')
    }
    if (this.adyenInstance || this.ppCardInstance) {
      this.kafkaReport.addTokenPayReport?.('channelSdkOnLoad', '加载渠道js运行正常')
    }
    const payInfo = await this.handleUnifiedPay()
    const { status, result } = payInfo || {}
    if (status === 'continue') {
      // 先清空，避免独立3ds失败兜底切换成渠道3ds时传入错误的值
      this.unifiedPayChallengeInfo.independThreedTraceID = ''

      const { action, paramList } = result?.info || {}
      if (action === 'render' && paramList) {
        this.handleLtspcPayRender({ res: result as Trade_PayLibs.UnifiedPayRes })
      } else if (action === 'challenge' && paramList) {
        this.handleLtspcPayChallenge({ res: result as Trade_PayLibs.UnifiedPayRes })
      } else if (action === 'redirect') {
        this.handleRedirectAction(result as Trade_PayLibs.UnifiedPayRes)
      } else {
        this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
      }
    } else {
      this.handleFrontPayResult(payInfo)
    }
  }
}

