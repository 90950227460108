// 支付单例
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { debuggerLog } from '../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { CHECKOUT_TYPE, PRODUCT_TYPE } from '../../types'
import{ type CheckstandModalData } from './CheckstandModal'
import { CreateNewWindow } from './helpers/CreateNewWindow'
import KafkaReport from './helpers/KafkaReport'
import { MonitorReportAbnormal, MonitorReportBusiness } from './helpers/MonitorReport'

export interface PayManagerActionMethods {
  paymentLoadingAction?: (info: { show: boolean }) => void;
}

/**
 * 抽象方法，子类需要实现
 *
 * @export
 * @interface AbstractMethods
 */
export interface AbstractMethods<C = any> {
  /**
   * 创建支付流程，一次成单/二次更新订单后开始走支付流程，大部分支付方式会直接请求统一支付接口，少部分需要先执行sdk交互逻辑
   *
   * @return {*}  {Promise<any>}
   * @memberof AbstractMethods
   */
  createPayment (): Promise<C>;
}

export interface BasicPayManagerConstructor extends Trade_PayLibs.UnifiedPayCbEvt {
  paymentCode?: string;
  payProductType?: PRODUCT_TYPE;
  payCheckoutType?: CHECKOUT_TYPE;
  payData: CheckstandModalData;
  extraParams: Record<string, any>;
  bsUtils: Trade_PayLibs.OutsideBsUtils;
  bsPayConfig: Trade_PayLibs.BsPayConfig;
  encryptConfig?: {
    encryptValid?: boolean;
  };
}

export class BasicPayManager {
  public paymentCode: string = ''

  public onRequestCompleted: BasicPayManagerConstructor['onRequestCompleted']

  public onSuccess: BasicPayManagerConstructor['onSuccess']

  public onFail: BasicPayManagerConstructor['onFail']

  public onError: BasicPayManagerConstructor['onError']

  public onPayContinued: BasicPayManagerConstructor['onPayContinued']

  public onPayPaused: BasicPayManagerConstructor['onPayPaused']

  public onCancel: BasicPayManagerConstructor['onCancel']

  public payData: CheckstandModalData = {} as CheckstandModalData

  public extraParams: Record<string, any> = {}

  public unifiedPayParams: CheckstandModalData['unifiedPayParams'] = {} as CheckstandModalData['unifiedPayParams']

  public payProductType: PRODUCT_TYPE = PRODUCT_TYPE.NORMAL

  public payCheckoutType: CHECKOUT_TYPE = CHECKOUT_TYPE.NORMAL

  public bsUtils: Trade_PayLibs.OutsideBsUtils = {} as Trade_PayLibs.OutsideBsUtils

  public bsPayConfig: Trade_PayLibs.BsPayConfig = {} as Trade_PayLibs.BsPayConfig

  public appConfigs = useAppConfigs()

  public kafkaReport

  private encryptConfig

  public paymentLoadingAction: PayManagerActionMethods['paymentLoadingAction'] = () => { }

  constructor({
    payData,
    extraParams,
    payProductType,
    payCheckoutType,
    onCancel,
    onError,
    onRequestCompleted,
    onSuccess,
    onFail,
    onPayContinued,
    onPayPaused,
    bsUtils,
    bsPayConfig,
    encryptConfig,
  }: BasicPayManagerConstructor) {
    this.payData = payData || {}
    this.extraParams = extraParams || {}
    this.unifiedPayParams = payData.unifiedPayParams || {}
    this.paymentCode = this.payData.paymentCode || this.paymentCode

    this.bsUtils = bsUtils || this.bsUtils
    this.payProductType = payProductType || bsPayConfig.productType as PRODUCT_TYPE
    this.payCheckoutType = payCheckoutType || bsPayConfig.checkoutType
    this.bsPayConfig = bsPayConfig || this.bsPayConfig

    this.onCancel = onCancel || this.onCancel
    this.onSuccess = onSuccess || this.onSuccess
    this.onFail = onFail || this.onFail
    this.onError = onError || this.onError
    this.onRequestCompleted = onRequestCompleted || this.onRequestCompleted
    this.onPayContinued = onPayContinued || this.onPayContinued
    this.onPayPaused = onPayPaused || this.onPayPaused
    this.encryptConfig = encryptConfig || this.encryptConfig

    this.kafkaReport = new KafkaReport()

    this.normalPushKafkaInfo()
  }

  get usePciApi () {
    const { tokenId, routeId, c2pFlag } = this.unifiedPayParams
    const isPciPayment = this.bsUtils.checkLtspcPaymentMethod?.(this.paymentCode)
    return isPciPayment && !!(tokenId || routeId || [CHECKOUT_TYPE.FRONT_PAY, CHECKOUT_TYPE.FRONT_TOKEN].includes(this.payCheckoutType) || c2pFlag)
  }

  get preRoutingInfo () {
    return this.payData.preRoutingInfo || {}
  }

  private normalUnifiedPayApi = (formData: Trade_PayLibs.UnifiedPayParams) => {
    const url = '/api/pay/unifiedPay/create'
    this.kafkaReport?.updateKafkaData?.({
      neurStep: formData.neurStep,
      neurPayId: formData.neurPayId,
      startTime: +new Date(),
      callUrl: url,
      billno: formData.billno,
      paymentMethod: formData.paymentCode,
    })
    return this.appConfigs.$schttp<Trade_PayLibs.UnifiedPayRes>({
      baseURL: this.appConfigs.$envs.langPath, // 调用外部接口
      url,
      method: 'POST',
      data: formData,
    })
  }

  private pciUnifiedPayApi = (formData: Trade_PayLibs.UnifiedPayParams) => {
    const url = '/ltspc/pay/unPay/unifiedPay'
    this.kafkaReport?.updateKafkaData?.({
      neurStep: formData.neurStep,
      neurPayId: formData.neurPayId,
      startTime: +new Date(),
      callUrl: url,
      billno: formData.billno,
      paymentMethod: formData.paymentCode,
    })
    return this.appConfigs.$schttp<Trade_PayLibs.UnifiedPayRes>({
      baseURL: this.appConfigs.$envs.langPath, // 调用外部接口
      url,
      method: 'POST',
      data: formData,
    })
  }

  private pciUnifiedPayCompleteApi = (formData: Trade_PayLibs.UnifiedPayCompleteParams) => {
    const url = '/ltspc/pay/unPay/complete'
    this.kafkaReport?.updateKafkaData?.({
      neurStep: formData.neurStep,
      neurPayId: formData.neurPayId,
      startTime: +new Date(),
      callUrl: url,
      billno: formData.billno,
      paymentMethod: formData.paymentCode,
    })
    return this.appConfigs.$schttp<Trade_PayLibs.UnifiedPayRes>({
      baseURL: this.appConfigs.$envs.langPath, // 调用外部接口
      url,
      method: 'POST',
      data: formData,
    })
  }
  // /unifiedCallback/update
  private normalUnifiedCallbackApi = (params: Trade_PayLibs.UnifiedPayCbParams) => {
    const url = '/api/pay/unifiedCallback/update'
    this.kafkaReport?.updateKafkaData?.({
      neurStep: params.neurStep,
      neurPayId: params.neurPayId,
      startTime: +new Date(),
      callUrl: url,
    })
    return this.appConfigs.$schttp<Trade_PayLibs.UnifiedPayRes>({
      baseURL: this.appConfigs.$envs.langPath, // 调用外部接口
      url,
      params: params || {},
    })
  }

  basicUnHandleResult = (res: Trade_PayLibs.UnifiedPayRes): Trade_PayLibs.BasicUnHandleResult => {
    debuggerLog('basicUnHandleResult', res)
    this.kafkaReport?.sendData?.({ res, resStatus: 200 })
    if (res?.code == 0 && res?.info) {
      const {
        action,
        result,
        show_error_msg,
        error_code,
        pending,
      } = res.info

      //跳转支付
      if (action == 'direct' && result == 1) {
        //直接返回支付结果
        return {
          status: 'success',
          result: res,
        }
      } else if (action == 'direct' && result == 0 && pending == 1) {
        return {
          status: 'pending',
          result: res,
        }
      } else if (result == 0 && error_code) {
        //处理报错文案，如果有客服链接，将{0}替换成langPath以适应不同站点
        const reg = /\{0\}\/robot/
        if (show_error_msg && reg.test(show_error_msg)) {
          res.info.show_error_msg = show_error_msg.replace(
            reg,
            this.appConfigs.$envs?.langPath + '/robot',
          )
        }
        return {
          status: 'error',
          result: res,
        }
      } else if (res.customInfo?.isCancel == 1) {
        MonitorReportAbnormal.metric({
          scene: 'unified_pay_create_cancel',
          extraTags: {
            failure_type: 'api',
            payment_code: this.unifiedPayParams.paymentCode,
          },
          extraParams: {
            client_url: 'basicUnHandleResult',
            fail_case: 'cancel',
            billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          },
        })
        return {
          status: 'cancel',
          result: res,
        }
      }

      return {
        status: 'continue',
        result: res,
      }
    } else { // 订单已付款
      if (res?.code == '300317') {
        return {
          status: 'success',
          result: res,
        }
      }
      return {
        status: 'error',
        result: res,
      }
    }
  }

  private unifiedPay = async (): Promise<Trade_PayLibs.BasicUnHandleResult> => {
    try {
      const unifiedPayApi = this.usePciApi ? this.pciUnifiedPayApi : this.normalUnifiedPayApi
      const result = await unifiedPayApi(this.unifiedPayParams)
      const data = result.data || result
      debuggerLog('handleUnifiedPay===result====', data)

      let status_code = data?.code || ''
      if (+status_code == 0) {
        status_code = data?.info?.error_code || status_code
      }
      MonitorReportBusiness.metric({
        scene: 'business_unified_pay_create',
        extraTags: {
          payment_code: this.unifiedPayParams.paymentCode,
          status_code,
        },
        extraParams: {
          pay_case: this.usePciApi ? 'pci_unified_pay' : 'normal_unified_pay',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
        },
      })
      return this.basicUnHandleResult(data)
    } catch (error) {
      debuggerLog(error)
      this.kafkaReport?.sendData?.({ resStatus: (error as any)?.status || '-1' })

      MonitorReportAbnormal.metric({
        scene: 'unified_pay_create_fail',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          client_url: '/pay/unifiedPay/create',
          fail_case: 'catchError',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          error_info: JSON.stringify(error || {}),
          error_msg: (error as any)?.message || '',
        },
      })
      return {
        status: 'catchError',
        error: error,
      }
    }
  }

  public updateUnifiedPayParams = (params: Partial<CheckstandModalData['unifiedPayParams']>) => {
    this.unifiedPayParams = {
      ...this.unifiedPayParams,
      ...params,
    }
    debuggerLog(`updateUnifiedPayParams====${this.paymentCode}===`, params, this.unifiedPayParams)
  }

  // 支付
  public async handleUnifiedPay() {
    debuggerLog('Basic支付', this.unifiedPay, this.paymentCode, this.unifiedPayParams)
    if (!this.paymentCode) {
      throw new Error('请先调用setup方法')
    }

    const res = await this.unifiedPay()
    debuggerLog('Basic支付结果', res)
    if (res?.status !== 'continue') {
      this.onRequestCompleted?.(res?.result || {} as Trade_PayLibs.UnifiedPayRes)
    }
    if (['error', 'cancel', 'catchError'].includes(res?.status)) {
      const unifiedPayApiUrl = this.usePciApi ? '/ltspc/pay/unPay/unifiedPay' : '/api/pay/unifiedPay/create'
      MonitorReportAbnormal.metric({
        scene: 'unified_pay_create_fail',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          client_url: unifiedPayApiUrl,
          fail_case: 'apiError',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          error_info: JSON.stringify(res.result?.info || {}),
          error_msg: res.result?.info?.show_error_msg || res.result?.info?.error_msg,
        },
      })
    }
    return res
  }

  public async handleUnifiedPayComplete (params: Trade_PayLibs.UnifiedPayCompleteParams) {
    const result = await this.pciUnifiedPayCompleteApi(params)
    const resData = result.data || result
    const res = this.basicUnHandleResult(resData)
    let status_code = resData?.code || ''
    if (+status_code == 0) {
      status_code = resData?.info?.error_code || status_code
    }

    MonitorReportBusiness.metric({
      scene: 'business_unified_pay_create',
      extraTags: {
        payment_code: this.unifiedPayParams.paymentCode,
        status_code,
      },
      extraParams: {
        pay_case: 'pci_complete',
        billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
      },
    })

    debuggerLog('handleUnifiedPayComplete===', res, resData)

    if (['error', 'cancel', 'catchError'].includes(res?.status)) {
      MonitorReportAbnormal.metric({
        scene: 'unified_pay_create_cb_fail',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          client_url: '/ltspc/pay/unPay/complete',
          fail_case: 'apiError',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          error_info: JSON.stringify(res.result?.info || {}),
          error_msg: res.result?.info?.show_error_msg || res.result?.info?.error_msg,
        },
      })
    }
    if (res?.status !== 'continue') {
      this.onRequestCompleted?.(res?.result || {} as Trade_PayLibs.UnifiedPayRes)
    }
    return res
  }

  public async handleUnifiedCallback (params: Trade_PayLibs.UnifiedPayCbParams) {
    const result = await this.normalUnifiedCallbackApi(params)
    const resData = result.data || result
    const res = this.basicUnHandleResult(resData)

    let status_code = resData?.code || ''
    if (+status_code == 0) {
      status_code = resData?.info?.error_code || status_code
    }

    MonitorReportBusiness.metric({
      scene: 'business_unified_pay_cb',
      extraTags: {
        payment_code: this.unifiedPayParams.paymentCode,
        status_code,
      },
      extraParams: {
        billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
      },
    })

    if (['error', 'cancel', 'catchError'].includes(res?.status)) {
      MonitorReportAbnormal.metric({
        scene: 'unified_pay_create_cb_fail',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          client_url: '/api/pay/unifiedCallback/update',
          fail_case: 'apiError',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          error_info: JSON.stringify(res.result?.info || {}),
          error_msg: res.result?.info?.show_error_msg || res.result?.info?.error_msg,
        },
      })
    }

    if (res?.status !== 'continue') {
      this.onRequestCompleted?.(res?.result || {} as Trade_PayLibs.UnifiedPayRes)
    }
    return res
  }

  // 一次成单/二次更新订单后开始走支付流程，大部分支付方式会直接请求统一支付接口，少部分需要先执行sdk交互逻辑
  // 如果需要sdk交互逻辑，需要在子类中重写此方法
  // public async createPayment () {
  //   // return this.handleUnifiedPay()
  // }

  private normalPushKafkaInfo = () => {
    if (this.unifiedPayParams.forterDeviceFingerprintID) {
      this.kafkaReport.addTokenPayReport?.('forterDeviceFingerprintID', '风控数据采集正常')
    }
    if (this.unifiedPayParams.cardNoHash || this.unifiedPayParams.tokenId) {
      this.kafkaReport.addTokenPayReport?.('publicKeyObjKey', this.unifiedPayParams.publicKeyId ? '加载加解密公钥正常' : '加解密公钥获取异常')
    }
    if (this.unifiedPayParams.cardNoHash) {
      this.kafkaReport.addTokenPayReport?.('encryptValid', this.encryptConfig?.encryptValid ? '卡号加密成功' : '卡号加密失败')
    }
  }

  public normalHandleRedirectAction (result: Trade_PayLibs.UnifiedPayRes) {
    const actionUrl = result?.info?.actionUrl || ''
    debuggerLog('handleRedirectAction===', result, actionUrl)
    if (actionUrl) {
      if (this.bsPayConfig?.useInline) {
        CreateNewWindow.continueRedirect({ actionUrl })
      } else {
        window.location.href = actionUrl
      }
    } else {
      this.onFail?.(result)
      CreateNewWindow.destroy?.()
    }
  }
  // 默认的useInline场景支付回调处理，如果有特殊需求，可以在子类中重写此方法
  public newWindowCompleteCb (source: Trade_PayLibs.OutsideBsResult) {
    debuggerLog('newWindowCompleteCb====', source, this.onCancel, this)
    if (source.status === 'cancel') {
      CreateNewWindow.destroy?.()
      MonitorReportAbnormal.metric({
        scene: 'new_window_complete_cb_cancel',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
        },
      })
      return this.onCancel?.()
    }
    if (source.status === 'fail') {
      CreateNewWindow.destroy?.()
      MonitorReportAbnormal.metric({
        scene: 'new_window_complete_cb_fail',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
        },
      })
      return this.onFail?.(source.result as Trade_PayLibs.UnifiedPayRes)
    }
    const { status, result } = this.basicUnHandleResult(source.result)
    if (['error', 'cancel', 'catchError'].includes(status)) {
      MonitorReportAbnormal.metric({
        scene: 'unified_pay_create_cb_fail',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          client_url: 'new_window_complete_cb_fail',
          fail_case: 'apiError',
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          error_info: JSON.stringify(result?.info || {}),
          error_msg: result?.info?.show_error_msg || result?.info?.error_msg,
        },
      })
    }
    if (status !== 'continue') {
      this.onRequestCompleted?.(result || {} as Trade_PayLibs.UnifiedPayRes)
    }
    if (status === 'success' || status === 'pending') {
      CreateNewWindow.destroy?.()
      this.onSuccess?.(result as Trade_PayLibs.UnifiedPayRes)
    } else if (status === 'error') {
      this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
      CreateNewWindow.destroy?.()
    } else if (status === 'catchError') {
      this.onFail?.(result as any)
      CreateNewWindow.destroy?.()
    } else if (status === 'cancel') {
      this.onCancel?.()
      CreateNewWindow.destroy?.()
    }
  }

  public bindingMethods = (methods: PayManagerActionMethods) => {
    this.paymentLoadingAction = methods.paymentLoadingAction || this.paymentLoadingAction
  }
  // TODO 待废弃
  public sendPaymentError = () => {
  // public sendPaymentError = (params: Trade_PayLibs.PaymentErrorType & Trade_PayLibs.PaymentErrorType['error_data']) => {
    // this.bsUtils?.sendPaymentError?.(params?.payment_error_scene || '', {
    //   failure_type: params?.failure_type || '',
    //   product_type: this?.payProductType || '',
    //   payment_action_type: params?.payment_action_type || 'paypal_sdk',
    //   client_url: params?.client_url || '',
    //   error_data: {
    //       status_code: params?.status_code,
    //       uorder_id: this.unifiedPayParams?.relation_billno,
    //       order_no: this.unifiedPayParams?.billno,
    //       payment_method: this.payData.paymentCode,
    //       description: params?.description || '',
    //   },
    // })
  }
}

