import { createStore, type StoreApi } from '../hooks/useImplStore'
import { type C_Libs_Pay, type ApolloInfo, PRODUCT_TYPE, CHECKOUT_TYPE } from '../../types'
import { type Trade_PayToolKit, Trade_PayLibs } from '@shein-aidc/types-trade'
import type { FormatExtraInfo } from '../utils/format'
import { type LangKeys } from '../constants'

export type StoreInfo = {
  scene: string;
  bsPayConfig: Trade_PayLibs.BsPayConfig<PRODUCT_TYPE, CHECKOUT_TYPE>;
  paySessionInfo: Record<string, any>;
  selectedPayment: Trade_PayToolKit.AssembledToOutsidePaymentInfo;
  unifiedPayOptions: C_Libs_Pay.UnifiedPayOrgs<C_Libs_Pay.UnifiedPayParams>;
  apolloInfo: ApolloInfo;
  abtInfo: Record<string, Trade_PayToolKit.AbtInfo>;
  extraOrderInfo: FormatExtraInfo;
  bsUtils: Trade_PayLibs.OutsideBsUtils;
  ltspcUrl: string;
  preRoutingInfo: Trade_PayToolKit.PreRoutingInfo;
  encryptConfig: {
    encryptValid: boolean;
    publicKeyObj: Record<string, any>;
    encryptObj: Record<string, any>;
  };
  language: Record<LangKeys, string>;
  selectedTokenInfo: Record<string, any>;
  sessionInfoParams: Record<string, any>;
}

export type PaymentProcessorStore = StoreApi<StoreInfo>

export const initializeStore = () => {
  return createStore<StoreInfo>(() => ({
    scene: '',
    bsPayConfig: {
      useInline: false,
      environment: 'PRODUCTION',
      checkoutType: CHECKOUT_TYPE.NORMAL,
      productType: PRODUCT_TYPE.NORMAL,
    },
    paySessionInfo: {},
    selectedPayment: {} as Trade_PayToolKit.AssembledToOutsidePaymentInfo,
    unifiedPayOptions: {} as C_Libs_Pay.UnifiedPayOrgs<C_Libs_Pay.UnifiedPayParams>,
    apolloInfo: {} as ApolloInfo,
    abtInfo: {},
    extraOrderInfo: {} as FormatExtraInfo,
    bsUtils: {} as Trade_PayLibs.OutsideBsUtils,
    ltspcUrl: '',
    preRoutingInfo: {} as Trade_PayToolKit.PreRoutingInfo,
    encryptConfig: {
      encryptValid: false as boolean,
      publicKeyObj: {} as Record<string, any>,
      encryptObj: {} as Record<string, any>,
    },
    language: {} as Record<LangKeys, string>,
    selectedTokenInfo: {} as Record<string, any>,
    sessionInfoParams: {} as Record<string, any>,
  }))
}

