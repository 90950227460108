import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { loadChannelSdk } from '../../channel/initSdk'
import { debuggerLog } from '../../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
// import { CHECKOUT_TYPE, PRODUCT_TYPE } from '../../../types'

export interface PaidyPayManagerConstructor extends BasicPayManagerConstructor { }

interface PaidyCallbackData {
  status: string;
  id?: string;
}

interface PaidyConfig {
  api_key: string; // 从 Paidy 后台获取的公钥
  token: { // 用于认证的令牌
    type: string;
  };
  on_close: (callbackData: PaidyCallbackData | null) => void; // 当 Paidy 支付窗口关闭时的回调函数
}

interface channelTradeConfigType {
  public_key?: string;
  channelTokenType?: string;
  billingName?: string;
}

export interface GetChannelConfigRes {
  code?: number | string;
  info?: channelTradeConfigType;
  tips?: string;
  msg?: string;
}

export interface UsePaidyPayReturnType {
  initPaidySdk: () => void;
  getChannelConfig: () => void;
  PAYMENT_CODE: string;
}

// interface OrderInfoType {
//   buyer_name?: string;
//   [key: string]: any;
// }

export class PaidyPayManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = 'paidy-paylater'

  public paymentCode: string = PaidyPayManager.PAYMENT_CODE

  private channelTradeConfig: channelTradeConfigType = {}

  private paidyConfig: PaidyConfig = {} as PaidyConfig

  private resolve

  private reject

  constructor(params: PaidyPayManagerConstructor) {
    super(params)

    this.resolve = null
    this.reject = null
  }

  private closeCb = (callbackData: PaidyCallbackData | null) => {
    debuggerLog('callbackData===', callbackData)
    const { status, id } = callbackData || {}
    if (status === 'active' && id) { // status = closed 关闭弹窗
      this.paymentLoadingAction?.({ show: true })
      this.updateUnifiedPayParams({ sessionId: id })
      this.handleUnifiedPay().then(this.resolve).catch(this.reject)
    } else {
      this.onCancel?.()
      this.resolve?.({ status: 'cancel' })
      MonitorReportAbnormal.metric({
        scene: 'paidy_paylater_close_cb',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: { callbackData, status },
      })
    }
    // 回参为null，或status=active时未正确返回id则上报
    if (!callbackData || (status === 'active' && !id)) {
      this.reject?.()
      MonitorReportAbnormal.metric({
        scene: 'paidy_paylater_close_cb_error',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: { callbackData, status },
      })
    }
  }

  private launch = () => {
    this.paidyConfig.on_close = this.closeCb.bind(this)
    debuggerLog('paidyConfig===', this.paidyConfig)
    const paidyHandler = (window as any).Paidy.configure(this.paidyConfig)
    if (!((window as any).Paidy)) {
      this.reject?.({ code: 1001, message: 'Paidy is not defined' })
      MonitorReportAbnormal.metric({
        scene: 'paidy_paylater_sdk_render_error',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: { api_key: this.paidyConfig?.api_key, token_type: this.paidyConfig?.token?.type },
      })
      return
    }
    if (!paidyHandler) {
      this.reject?.({ code: 1002, message: 'Paidy handler is not defined' })
      MonitorReportAbnormal.metric({
        scene: 'paidy_paylater_configure_init_error',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: { api_key: this.paidyConfig?.api_key, token_type: this.paidyConfig?.token?.type },
      })
      return
    }
    paidyHandler?.launch?.({
      store_name: 'SHEIN',
      buyer: {
        name1: this.channelTradeConfig?.billingName || '',
      },
    })
  }

  public static initPaidySdk = async () => {
    await loadChannelSdk.initPaidySdk()
  }

  private getChannelConfig = async () => {
    const { billno, relation_billno } = this.payData.unifiedPayParams
    const result = await this.appConfigs.$schttp<GetChannelConfigRes>({
      baseURL: this.appConfigs.$envs?.langPath,
      url: '/api/checkout/channelTradeConfig/get',
      params: { billno: relation_billno || billno, paymentCode: this.paymentCode },
    })

    const data: GetChannelConfigRes = result?.data || result

    debuggerLog('getChannelConfig===result===', result)

    this.paymentLoadingAction?.({ show: false })
    if (data?.code != 0) {
      this.reject?.(data as any)
      MonitorReportAbnormal.metric({
        scene: 'paidy_paylater_get_channel_config_error',
        extraTags: {
          status_code: data?.code,
          payment_code: this.paymentCode,
        },
        extraParams: {
          billno: relation_billno || billno,
          error_msg: data?.tips || data?.msg,
        },
      })
    } else {
      const info = data?.info

      if (info) {
        this.channelTradeConfig = info || {}
        this.paidyConfig = {
          ...this.paidyConfig,
          api_key: info?.public_key || '',
          token: {
            type: info?.channelTokenType || '',
          },
        }

        this.launch()
      }

      if (!info?.billingName) {
        MonitorReportAbnormal.metric({
          scene: 'paidy_paylater_fail_to_get_name',
          extraTags: {
            payment_code: this.paymentCode,
          },
          extraParams: {
            billno: relation_billno || billno,
          },
        })
      }

      if (!info?.public_key || !info?.channelTokenType) {
        MonitorReportAbnormal.metric({
          scene: 'paidy_paylater_fail_to_get_info',
          extraTags: {
            payment_code: this.paymentCode,
          },
          extraParams: {
            billno: relation_billno || billno,
          },
        })
      }
    }
  }


  public async createPayment (): Promise<any> {
    return new Promise((resolve, reject) => {
      this.paymentLoadingAction?.({ show: true })
      this.resolve = (res) => {
        this.paymentLoadingAction?.({ show: false })
        if (res?.status === 'success' || res?.status === 'pending') {
          this.onSuccess?.(res.result as Trade_PayLibs.UnifiedPayRes)
        } else if (res.status === 'error') {
          this.onError?.(res.result as Trade_PayLibs.UnifiedPayRes)
        } else if (res.status === 'catchError') {
          this.onFail?.(res.result as any)
        }
        resolve(res)
      }
      this.reject = (error) => {
        this.paymentLoadingAction?.({ show: false })
        this.onError?.(error)
        reject(error)
      }
      this.getChannelConfig()
    })
  }
}
