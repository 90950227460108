import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { debuggerLog } from '../../utils'
import { MonitorReportAbnormal, MonitorReportBusiness } from '../helpers/MonitorReport'
import { PAYMENT_ACTION_TYPE } from '../../../types'
import { PC_BLIK_PAY_METHODS } from '../../constants'

interface BlikPayDialogManagerConstructor extends BasicPayManagerConstructor {}

type BlikCodeComOptions = {
  status?: BlikCodeComStatus
  handleClose?: () => void
  handleSubmit?: ({ type, code }) => void
  handleSuccess?: (url: string) => void
}

interface BlikCodeComStatus {
  pending?: boolean
  loading?: boolean
  error?: boolean
  errorText?: string
  codeList?: string
  show?: boolean
}

export class BlikPayDialogManager extends BasicPayManager implements AbstractMethods {
  private static dialogMethods: {
    showBlikCodeCom: (options: BlikCodeComOptions) => void
    changeBlikCodeStatus: (status: BlikCodeComStatus) => void
  }

  constructor (config: BlikPayDialogManagerConstructor) {
    super(config)
  }

  static paymentMethods = PC_BLIK_PAY_METHODS

  static bindBlikCodeComMethods (methods: typeof BlikPayDialogManager.dialogMethods) {
    BlikPayDialogManager.dialogMethods = methods
  }

  private async handlePaymentSubmit({ type, code }: { type: string, code: string }) {
    const formData = {
      verificationCode: code || '',
      checkout_type: type || '',
    }
    this.updateUnifiedPayParams({ ...formData })
    const { status, result } = await this.handleUnifiedPay()
    const { action, paramList, actionUrl, error_code, result: _result, show_error_msg, error_msg } = result?.info || {}
    const { changeBlikCodeStatus } = BlikPayDialogManager.dialogMethods
    if (action === 'redirect') {
      if (!actionUrl) {
        changeBlikCodeStatus({
          pending: true,
          error: false,
          errorText: '',
        })
        MonitorReportBusiness.metric({
          scene: 'blik_dialog_pay_pending',
          extraTags: {
            payment_code: this.unifiedPayParams.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams.billno,
            actionUrl,
            paramList: JSON.stringify(paramList || {}),
            action,
            payment_action_type: PAYMENT_ACTION_TYPE.ORIGIN_PAGE_DIALOG,
          },
        })
        return
      }
    } else if (action == 'direct' ) {
      if ([7539, 7584].includes(+error_code) && _result == '0') {
        changeBlikCodeStatus({
          error: true,
          loading: false,
          errorText: show_error_msg || error_msg,
        })
        MonitorReportAbnormal.metric({
          scene: 'blik_dialog_pay_pending',
          extraTags: {
            payment_code: this.unifiedPayParams.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams.billno,
            error_msg: show_error_msg || error_msg,
            actionUrl,
            paramList: JSON.stringify(paramList || {}),
            action,
            payment_action_type: PAYMENT_ACTION_TYPE.ORIGIN_PAGE_DIALOG,
          },
        })
        return
      } else {
        changeBlikCodeStatus({
          error: false,
          loading: false,
          show: false,
          codeList: '',
        })
      }
      if (status === 'success') {
        this.onSuccess?.(result as any)
      } else if (status === 'cancel') {
        this.onCancel?.()
      } else {
        this.onError?.(result as any)
      }
    }
  }

  private handlePaymentCancel() {
    this.onCancel?.()
  }

  private handlePaymentSuccess(url: string) {
    location.href = url
  }

  private startPay() {
    debuggerLog('startBlikPay', BlikPayDialogManager.dialogMethods)
    if (!BlikPayDialogManager.dialogMethods) return
    const { showBlikCodeCom } = BlikPayDialogManager.dialogMethods
    showBlikCodeCom({
      handleSubmit: this.handlePaymentSubmit.bind(this),
      handleClose: this.handlePaymentCancel.bind(this),
      handleSuccess: this.handlePaymentSuccess.bind(this),
    })
  }

  public createPayment(): Promise<any> {
    this.startPay()
    return Promise.resolve()
  }
}
