import { BasicPayManager, type BasicPayManagerConstructor } from '../BasicPayManager'
import { CreateNewWindow } from '../helpers/CreateNewWindow'
import { debuggerLog } from '../../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
// import { CHECKOUT_TYPE, PRODUCT_TYPE } from '../../../types'

export interface NormalPayManagerConstructor extends BasicPayManagerConstructor { }

export class NormalPayManager extends BasicPayManager {

  public static PAYMENT_CODE = ''

  private resolve

  private reject

  constructor(params: NormalPayManagerConstructor) {
    super(params)

    this.resolve = null
    this.reject = null

    debuggerLog('NormalPayManager', params)
  }

  private _sendReport = () => { }

  private handleRedirectAction = (result: Trade_PayLibs.UnifiedPayRes) => {
    const actionUrl = result?.info?.actionUrl || ''
    debuggerLog('handleRedirectAction===', result, actionUrl)
    if (actionUrl) {
      // window.location.href = actionUrl
      if (this.bsPayConfig?.useInline) {
        CreateNewWindow.continueRedirect({ actionUrl })
      } else {
        window.location.href = actionUrl
      }
    } else {
      this.reject?.(result)
      CreateNewWindow.destroy?.()
    }
  }


  public async createPayment (): Promise<any> {
    return new Promise((resolve, reject) => {
      this.paymentLoadingAction?.({ show: true })
      this.resolve = (res) => {
        if (res.status === 'success' || res.status === 'pending') {
          this.onSuccess?.(res.result as Trade_PayLibs.UnifiedPayRes)
          // CreateNewWindow.destroy?.()
        } else if (res.status === 'error') {
          this.onError?.(res.result as Trade_PayLibs.UnifiedPayRes)
          // CreateNewWindow.destroy?.()
        } else if (res.status === 'catchError') {
          this.onFail?.(res.result as any)
          // CreateNewWindow.destroy?.()
        } else if (res.status === 'cancel') {
          this.onCancel?.()
        } else {
          this.onCancel?.()
        }
        resolve(res)
        CreateNewWindow.destroy?.()
      }
      this.reject = (error) => {
        this.onError?.(error)
        reject(error)
        CreateNewWindow.destroy?.()
      }
      debuggerLog('createPayment====', this, this.unifiedPayParams)
      this.handleUnifiedPay().then(res => {
        this.paymentLoadingAction?.({ show: false })
        if (res.status === 'continue') {
          if (res.result?.info?.action === 'redirect') {
            this.handleRedirectAction(res.result as Trade_PayLibs.UnifiedPayRes)
          } else {
            this.resolve?.(res)
          }
        } else {
          this.resolve?.(res)
        }
      }, err => {
        this.reject?.(err)
      })
    })
  }
}
