// 此文件为兜底逻辑，无特殊情况不在更新

const DEFAULT_MERCHANTCAPABILITIES = ['supports3DS', 'supportsEMV']
const DEFAULT_SUPPORTEDNETWORKS = ['visa', 'masterCard', 'amex', 'chinaUnionPay']
const applePayConfig = {
  applePayChannel: ['checkout-applepay', 'adyen-applepay', 'worldpay-applepay', 'ingenico-applepay', 'nuvei-applepay', 'routepay-applepay'],
  applePayTokenChannel: ['nuvei-applepay'], // applePayInfo取值event.payment.token
  'checkout-applepay': {
    version: 4,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: ['maestro'].concat(DEFAULT_SUPPORTEDNETWORKS),
  },
  'adyen-applepay': {
    version: 3,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: DEFAULT_SUPPORTEDNETWORKS,
  },
  'worldpay-applepay': {
    version: 3,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: DEFAULT_SUPPORTEDNETWORKS,
  },
  'ingenico-applepay': {
    version: 3,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: DEFAULT_SUPPORTEDNETWORKS,
  },
  'nuvei-applepay': {
    version: 4,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: ['discover', 'maestro'].concat(DEFAULT_SUPPORTEDNETWORKS),
  },
  'routepay-applepay': {
    version: 4,
    merchantCapabilities: DEFAULT_MERCHANTCAPABILITIES,
    supportedNetworks: DEFAULT_SUPPORTEDNETWORKS,
  },
}

export default applePayConfig
