interface CommonSendDdcParams {
  action: string
  method?: string
  params?: string | Record<string, any>
  timeout?: number
  cb?: (data: any) => void
}

interface RedirectUsingFormParams {
  action: string
  method?: string
  params?: string | Record<string, any>
}

// 倒计时器，用于处理ddc超时，超过2秒不等ddc返回，执行下一次操作
let timer: any

/**
 * 通用发送ddc请求
 * @param action 请求地址，统一支付返参中 paramList 中的 redirectUrl
 * @param method 请求方式，统一支付返参中 paramList 中的 method，默认为 POST
 * @param params 请求参数，统一支付返参中 paramList 中的 parameters
 * @param timeout 超时时间，默认2000ms
 * @param cb 回调函数
 * @returns void
 */
export function commonSendDdc({
  action,
  method = 'POST',
  params = '{}',
  timeout = 2000,
  cb,
}: CommonSendDdcParams): void {
  if (!action) {
    return
  }

  // #region 重置操作
  clearTimeout(timer)
  // #endregion

  // 获取host，用于匹配ddc返回的host
  let host = ''
  try {
    const urlObj = new URL(action)
    host = urlObj.protocol + '//' + urlObj.host
  } catch (e) {
    // eslint-disable-next-line no-useless-escape
    const reg = /((https?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+\.[-A-Za-z+]+\/+/g
    const hostUrl = action.match(reg)?.[0]
    host = hostUrl ? hostUrl.substring(0, hostUrl.length - 1) : ''
  }

  let paramsObj: Record<string, any> = {}
  if (typeof params === 'string') {
    try {
      paramsObj = JSON.parse(params)
    } catch (error) {
      //
    }
  } else {
    paramsObj = params
  }

  // ddc收集完成回调和超时回调只处理第一个执行的回调，后续的不再处理
  let hasRunCallback = false
  // 结果回调
  function returnCallback(data?: any) {
    if (hasRunCallback) return
    hasRunCallback = true
    cb && cb(data)
    window.removeEventListener('message', addDdcEvent, false)
  }

  // 添加ddc监听事件
  function addDdcEvent(event) {
    if (event.origin === host) {
      let data = {}
      if (typeof event.data === 'string') {
        try {
          data = JSON.parse(event.data)
        } catch (error) {
          //
        }
      }

      returnCallback(data)
    }
  }

  window.addEventListener('message', addDdcEvent, false)

  // 创建iframe和form
  const commonDdcIframeId = 'commonDdcIframe'
  const commonDdcFormId = 'commonDdcForm'
  let commonDdcIframe = document.getElementById(commonDdcIframeId) as HTMLIFrameElement | null
  let commonDdcForm = document.getElementById(commonDdcFormId) as HTMLFormElement | null

  if (!commonDdcIframe) {
    commonDdcIframe = document.createElement('iframe')
    commonDdcIframe.id = commonDdcIframeId
    commonDdcIframe.name = commonDdcIframeId
    commonDdcIframe.style.display = 'none'
    document.body.appendChild(commonDdcIframe)
  }

  if (!commonDdcForm) {
    commonDdcForm = document.createElement('form')
    commonDdcForm.id = commonDdcFormId
    document.body.appendChild(commonDdcForm)
  }

  commonDdcForm.target = commonDdcIframeId
  commonDdcForm.action = action
  commonDdcForm.method = method
  let inputsStr = ''
  for (const key in paramsObj) {
    inputsStr += `<input type="hidden" name="${key}" value="${paramsObj[key]}" />`
  }
  commonDdcForm.innerHTML = inputsStr
  commonDdcForm.submit()

  // 超时处理
  timer = setTimeout(() => {
    returnCallback()
  }, timeout)
}

/**
 * 通过表单形式跳转页面，一般用于 post 方式跳转页面（如渠道等）
 * @param {*} param.action 请求地址
 * @param {*} param.method 请求方式，默认为 POST
 * @param {*} param.params 请求参数
 * @returns
 */
export function redirectUsingForm({ action, method = 'POST', params = '{}' }: RedirectUsingFormParams): void {
  if (!action) {
    return
  }

  let paramsObj: Record<string, any> = {}
  if (typeof params === 'string') {
    try {
      paramsObj = JSON.parse(params)
    } catch (error) {
      //
    }
  } else {
    paramsObj = params
  }

  // form
  const redirectUsingFormId = 'redirectUsingForm'
  let redirectUsingForm = document.getElementById(redirectUsingFormId) as HTMLFormElement | null

  if (!redirectUsingForm) {
    redirectUsingForm = document.createElement('form')
    redirectUsingForm.id = redirectUsingFormId
    document.body.appendChild(redirectUsingForm)
  }

  redirectUsingForm.action = action
  redirectUsingForm.method = method
  let inputsStr = ''
  for (const key in paramsObj) {
    inputsStr += `<input type="hidden" name="${key}" value="${paramsObj[key]}" />`
  }
  redirectUsingForm.innerHTML = inputsStr
  redirectUsingForm.submit()
}
