
import { loadChannelSdk } from '../../channel/initSdk'
// import { nextIdle } from '../../utils'
import { clientInstanceCache } from '../caches'

export class InitPaypalSdk {
  private static get isLoaded () {
    if (typeof window == 'undefined') return false
    return !!window?.braintree
  }

  private static async getPayPalClientInstance({ clientToken }: { clientToken: string }) {
    if (typeof window == 'undefined') throw new Error('window is undefined')

    try {
      if (!this.isLoaded || !clientToken) return null
      const clientInstance = await (window as any).braintree?.client?.create?.({
          authorization: clientToken,
        })
        .catch(err => {
          throw err
        })
      return clientInstance
    } catch (error) {
      return Promise.reject(error)
    }
  }

  private static async getPaypalDeviceData(clientInstance) {
    if (!clientInstance) return Promise.reject('clientInstance is required')

    const dataCollectorInstance = await window.braintree?.dataCollector?.create?.({
        client: clientInstance,
      })
      .catch(err => {
        throw err
      })
    return dataCollectorInstance?.deviceData || ''
  }

  private static async getClientInstance(clientToken) {
    if (!clientToken) return null
    if (clientInstanceCache.has(clientToken)) return clientInstanceCache.get(clientToken)

    const ci = await this.getPayPalClientInstance({ clientToken })
    clientInstanceCache.set(clientToken, ci)
    return ci
  }

  public static async getPaypalDeviceInfo({ clientToken }) {
    if (!this.isLoaded || !clientToken) return ''
    const clientInstance = await this.getClientInstance(clientToken)
    const deviceData = await this.getPaypalDeviceData(clientInstance)
    return deviceData
  }

  public static initPaypalGaSdk = async ({
    clientToken,
  }) => {
    await loadChannelSdk.initPPGASdk()
    const clientInstance = await this.getClientInstance(clientToken)
    try {
      const paypalCheckoutInstance = await window.braintree?.paypalCheckout?.create?.({
        client: clientInstance,
      })
      return paypalCheckoutInstance
    } catch (error) {
      throw new Error('Failed to initialize PayPal Checkout SDK')
    }
  }

  static initPaypalVenmoSdk = async ({
    clientToken,
    profileId,
  }) => {
    await loadChannelSdk.initVenmoSdk()
    const clientInstance = await this.getClientInstance(clientToken)
    try {
      const venmoInstance = await window.braintree?.venmo?.create?.({
        client: clientInstance,
        profileId,
        allowDesktop: true,
        paymentMethodUsage: 'single_use', // available in v3.77.0+
      })
      return venmoInstance
    } catch (error) {
      throw new Error('Failed to initialize Venmo SDK')
    }
  }

  public static teardown = async (clientToken = '') => {
    if (clientToken) {
      const singleClientInstance = clientInstanceCache.get(clientToken)
      singleClientInstance?.teardown?.(() => {})
      clientInstanceCache?.delete?.(clientToken)
    } else {
      for (const key in clientInstanceCache) {
        if (clientInstanceCache.has(key)) {
          const clientInstance = clientInstanceCache.get(key)
          clientInstance?.teardown?.(() => {})
          clientInstanceCache.delete(key)
        }
      }
    }
  }
}
