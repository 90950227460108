import { debuggerLog } from '../../utils'

/**
 * @description 3ds验证
 */

export interface SdkBridgeActions {
  handleThreeDSecureModal: Function;
}

export class ThreeDSecureManager {

  static sdkBridgeActions: SdkBridgeActions = {
    handleThreeDSecureModal: (() => {}) as Function,
  }

  static bindThreeDSecureMethod = (methods: Record<keyof SdkBridgeActions, Function>) => {
    ThreeDSecureManager.sdkBridgeActions.handleThreeDSecureModal = methods?.handleThreeDSecureModal
  }

  static createMessageEventChange = ({
    callback,
  }: { callback: Function }) => {
    return (event: MessageEvent) => {
      const types = ['challenge', 'normal', 'ocpResult']
      debuggerLog('event---message----', event.data)
      if (event.origin == location.origin && event.data) {
        try {
          const data =
              typeof event.data === 'string'
                ? JSON.parse(event.data)
                : event.data || {}
          if (
            data !== undefined &&
              data.app === 'shein' &&
              types.includes(data.type)
          ) {
            const result = typeof data.result === 'string' ? JSON.parse(data.result) : data.result || {}
            callback?.({
              status: 'success',
              result,
            })
          }
        } catch (e) {
          debuggerLog('postMessage error: ' + e)
          callback?.({
            status: 'fail',
            result: e,
          })
        }
      }
    }
  }

  static makeForm = ({params, action, method = 'post'}: {params: any; action: string; method?: string;}) => {
    const formName = 'bs_pay_channel_form'
    let form = document.querySelector(`form[name="${formName}"]`)
    if (form && form.parentNode) {
      form.parentNode?.removeChild?.(form)
    }

    method = method || 'post'
    form = document.createElement('form')
    form.setAttribute('name', formName)
    form.setAttribute('action', action)
    form.setAttribute('method', method)

    const fragment = document.createDocumentFragment()

    for (const key in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', params[key])
      fragment.appendChild(input)
    }
    const submitInput = document.createElement('input')
    submitInput.setAttribute('type', 'submit')
    submitInput.setAttribute('value', 'Continue')
    submitInput.style.display = 'none'
    fragment.appendChild(submitInput)

    form.appendChild(fragment)

    document.body.appendChild(form)
    document[formName]?.submit?.()
  }

}
