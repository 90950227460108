import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { type Trade_PayLibs } from '@shein-aidc/types-trade'
import { debuggerLog } from '../../utils'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { PAYMENT_ACTION_TYPE } from '../../../types'

export interface SelfBuiltPagePayManagerConstructor extends BasicPayManagerConstructor { }

export class SelfBuiltPagePayManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = ''

  private static BLIK_PAYMENT_CODES = ['adyen-blikdirect', 'alipay-blik', 'routepay-blik']

  private static SCAN_CODE_PAY_METHODS = ['adyen-pix', 'adyen-boleto', 'alipay-pix']

  private static SELF_BUILT_PAGE_CASH_PAY_METHODS = ['adyen-konbini', 'adyen-multibanco', 'adyen-mbway', 'adyen-pix', 'adyen-boleto', 'alipay-pix', 'routepay-mbway']

  private static paymentCustomizeFlowPaymentCodes: string[] = []

  public static selfBuiltPagePaymentCodes: string[] = [...SelfBuiltPagePayManager.BLIK_PAYMENT_CODES, ...SelfBuiltPagePayManager.SELF_BUILT_PAGE_CASH_PAY_METHODS]

  constructor(params: SelfBuiltPagePayManagerConstructor) {
    super(params)
  }

  // private _sendReport = () => { }

  private static setSelfBuiltPagePaymentCodes(paymentCodes: string[]) {
    this.selfBuiltPagePaymentCodes = [...this.selfBuiltPagePaymentCodes, ...paymentCodes]
  }

  private handleBlikJump() {
    if (SelfBuiltPagePayManager.BLIK_PAYMENT_CODES.includes(this.paymentCode)) {
      const { $envs } = this.appConfigs || {}
      const { relation_billno, billno } = this.unifiedPayParams || {}
      const SITE_URL = `${$envs.host}${$envs.langPath}`
      // 跳转时下下单页body的top被设置了值，导致原来的路由跳转的blik_code_way页面被顶上去隐藏，这里先改成href跳转
      location.href = `${SITE_URL}/checkout/blik_code_way?billno=${relation_billno || billno}&paymentCode=${this.paymentCode}`
      this.paymentLoadingAction?.({ show: false })
      return true
    }
  }

  private getCashPayRedirectPath() {
    const { $envs, $getters } = this.appConfigs || {}
    const { relation_billno, billno } = this.unifiedPayParams || {}
    const SITE_URL = `${$envs.host}${$envs.langPath}`
    let cashPayRedirectPath = ''

    if (this.paymentCode === 'adyen-konbini') {
      cashPayRedirectPath = `${SITE_URL}/pay/konbini?billno=${relation_billno || billno}&from=${$getters.SaPageInfo?.page_name}`
    }
    if ('adyen-multibanco' === this.paymentCode) {
      cashPayRedirectPath = `${SITE_URL}/pay/mtbc?billno=${relation_billno || billno}&from=${$getters.SaPageInfo?.page_name}`
    }

    if (['adyen-mbway', 'routepay-mbway'].includes(this.paymentCode)) {
      // cashPayRedirectPath = `${SITE_URL}/pay/mbway?billno=${relation_billno || billno}&from=${$getters.SaPageInfo?.page_name}`
      cashPayRedirectPath = `${SITE_URL}/MB-WAY-a-1197.html?billno=${relation_billno || billno}&payment_code=${this.paymentCode}`
    }

    if ([...SelfBuiltPagePayManager.SCAN_CODE_PAY_METHODS, ...SelfBuiltPagePayManager.paymentCustomizeFlowPaymentCodes].includes(this.paymentCode)) {
      const urlHost = 'sheinisolatedform'
      cashPayRedirectPath = `${SITE_URL}/pay/${urlHost}?billno=${billno}&paymentCode=${this.paymentCode}&giftcard=${0}`
    }

    return cashPayRedirectPath
  }

  private selfBuiltPageResultHandler(result: Trade_PayLibs.UnifiedPayRes) {
    const { $envs } = this.appConfigs || {}
    const { relation_billno, billno } = this.unifiedPayParams || {}
    const SITE_URL = `${$envs.host}${$envs.langPath}`
    const {
      paramList = {},
      // is_guide = '',
      type = '',
    } = result?.info || {}
    const {
      reference = '',
      cashPayRedirectPath = '',
      entity = '',
    } = paramList || {}

    // const unifiePath = type === 'giftcard' ? '/giftcard' : ''

    if (this.paymentCode == 'adyen-konbini') {
      window.localStorage.setItem('ADYEN_KONBINI_REFERENCE', reference)
    }

    if ('adyen-multibanco' === this.paymentCode) {
      window.localStorage.setItem(
        'MULTIBANCO_REFERENCE',
        JSON.stringify({ reference, entity }),
      )
    }

    if ([...SelfBuiltPagePayManager.SCAN_CODE_PAY_METHODS, ...SelfBuiltPagePayManager.paymentCustomizeFlowPaymentCodes].includes(this.paymentCode)) {
      // 兜底
      if (
        (!paramList?.qrCodeData && this.paymentCode == 'adyen-pix') ||
        !cashPayRedirectPath
      ) {
        this.onError?.(result)
        MonitorReportAbnormal.metric({
          scene: 'self_built_jump_error',
          extraTags: {
            payment_code: this.paymentCode,
          },
          extraParams: {
            billno: relation_billno || billno,
            cashPayRedirectPath: cashPayRedirectPath || '',
            paramList: JSON.stringify(paramList || {}),
            payment_action_type: PAYMENT_ACTION_TYPE.REDIRECT_SELF_PAGE,
          },
        })
        return
      }
      try {
        window.localStorage.setItem(
          'FORMPAY',
          JSON.stringify({
            giftcard: type === 'giftcard' ? 1 : 0,
            ...paramList,
          }),
        )
      } catch (e: any) {
        if (e?.name === 'QuotaExceededError') {
          // 本地存储已满，采取相应措施
          // if (isBuyXtra || isBuyPrime) {
          //   location.href = `${SITE_URL}/user/orders/virtualDetail/${billno}`
          //   return
          // }
          MonitorReportAbnormal.metric({
            scene: 'self_built_local_storage_quota_exceeded',
            extraTags: {
              payment_code: this.paymentCode,
            },
            extraParams: {
              billno: relation_billno || billno,
              payment_action_type: PAYMENT_ACTION_TYPE.REDIRECT_SELF_PAGE,
            },
          })
          // this.appConfigs.$envs.project
          if (this.appConfigs.$envs.project === 'pwa') {
            location.href = `${SITE_URL}/user/orders/detail/${relation_billno || billno}`
          } else if (this.appConfigs.$envs.project === 'pc') {
            location.href = `${SITE_URL}/checkout/checkout_again/${relation_billno || billno}`
          }

          console.error('Local storage quota exceeded!')
        }
      }
    }

    window.location.href = cashPayRedirectPath
  }

  private handleRedirectAction = (result: Trade_PayLibs.UnifiedPayRes) => {
    const actionUrl = result?.info?.actionUrl || ''
    debuggerLog('SelfBuilt==handleRedirectAction===', result, actionUrl)
    if (actionUrl) {
      window.location.href = actionUrl
    } else {
      this.onError?.(result)
    }
  }

  public static initPaymentCustomizeFlowPaymentCodes(paymentCustomizeFlow = '') {
    const methods = paymentCustomizeFlow?.split?.(',')
    this.paymentCustomizeFlowPaymentCodes = methods
    this.setSelfBuiltPagePaymentCodes(methods)
  }

  public async createPayment(): Promise<any> {
    this.paymentLoadingAction?.({ show: true })
    if (SelfBuiltPagePayManager.BLIK_PAYMENT_CODES.includes(this.paymentCode)) {
      return this.handleBlikJump()
    }

    const cashPayRedirectPath = this.getCashPayRedirectPath()
    debuggerLog('cashPayRedirectPath====', cashPayRedirectPath, this.paymentCode)

    if (cashPayRedirectPath) {
      this.updateUnifiedPayParams({ cashPayRedirectPath, pageType: 1 })
      const { status, result } = await this.handleUnifiedPay()
      if (status === 'continue') {
        // fix bug: 兼容支付方式为adyen-mbway时，后端返回的action是redirect
        if (result?.info?.action === 'redirect') {
          this.handleRedirectAction(result)
        } else {
          this.selfBuiltPageResultHandler(result as Trade_PayLibs.UnifiedPayRes)
        }
      } else {
        if (status === 'success' || status === 'pending') {
          this.onSuccess?.(result as Trade_PayLibs.UnifiedPayRes)
        } else if (status === 'error') {
          this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
        } else if (status === 'catchError') {
          this.onFail?.(result as any)
        } else if (status === 'cancel') {
          this.onCancel?.()
        }
      }
      this.paymentLoadingAction?.({ show: false })
      return { status, result }
    }
  }
}
