import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { type ChannelSessionInfo } from '../../types'

export const useFetch = () => {
  const appConfigs = useAppConfigs()

  const bffGetNewChannelSessionApi = async (data: {
    shipCountry: string
    orderAmount: string
    orderCurrency: string
    payMethodList: string[]
    orderDetails: any[]
  }) => {
    return await appConfigs.$schttp<{ code: string; info: { sessions: ChannelSessionInfo[] }; }>({
      baseURL: appConfigs.$envs.langPath,
      method: 'POST',
      url: '/api/checkout/channelSession/create',
      data: {
        shipCountry: data.shipCountry,
        orderAmount: data.orderAmount,
        orderCurrency: data.orderCurrency,
        payMethodList: data.payMethodList,
        orderDetails: data.orderDetails,
      },
    })
  }

  return {
    bffGetNewChannelSessionApi,
  }
}

export type FetchMethods = ReturnType<typeof useFetch>
