import { CheckstandManager } from '../src/checkstand/CheckstandManager'
/* eslint-disable @typescript-eslint/no-namespace */
// import type {
//   AxiosRequestConfig,
//   AxiosPromise,
//   Method,
// } from '@shein-aidc/basis-schttp'

export declare namespace C_Libs_Pay {

  type ApplePayButtonLocale = 'ar-AB' | 'ca-ES' | 'cs-CZ' | 'da-DK' | 'de-DE' | 'el-GR' | 'en-AU' | 'en-CA' | 'en-GB' | 'en-US' | 'es-ES' | 'es-MX' | 'fi-FI' | 'fr-CA' | 'fr-FR' | 'he-IL' | 'hr-HR' | 'hu-HU' | 'id-ID' | 'it-IT' | 'ja-JP' | 'ko-KR' | 'ms-MY' | 'nb-NO' | 'nl-NL' | 'pl-PL' | 'pt-BR' | 'pt-PT' | 'ro-RO' | 'ru-RU' | 'sk-SK' | 'sv-SE' | 'th-TH' | 'tr-TR' | 'uk-UA' | 'vi-VN' | 'zh-CN' | 'zh-HK' | 'zh-TW'

  type ApplePayButtonType = 'add-money' | 'buy' | 'book' | 'check-out' | 'continue' | 'contribute' | 'donate' | 'order' | 'pay' | 'plain' | 'reload' | 'rent' | 'top-up' | 'select' | 'set-up' | 'subscribe' | 'tip'

  type ChannelSDkOptions = {
    preloadSDK: boolean
  }
  /**
   * 浏览器参数
   *
   * @interface PayBrowserParams
   */
  interface PayBrowserParams {
    /**
     * 浏览器语言
     *
     * @type {string}
     * @memberof PayBrowserParams
     */
    device_language: string;
    /**
     * 浏览器ua
     *
     * @type {string}
     * @memberof PayBrowserParams
     */
    userAgent: string;
    /**
     * accept
     *
     * @type {string}
     * @memberof PayBrowserParams
     */
    acceptHeader?: string;
    /**
     * colorDepth
     *
     * @type {string}
     * @memberof PayBrowserParams
     */
    colorDepth?: number | string;
    screenHeight: string;
    screenWidth: string;
    timeZoneOffset: string;
    javaEnabled?: string | number;
    javaScriptEnabled?: string | number;
    language: string;
  }
  /**
   * 需传给南京的回调url等参数
   *
   * @interface PayCbUrlParams
   */
  interface PayCbUrlParams {
    /**
     * 回调地址
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    callbackUrl: string; // 回调地址
    /**
     * 取消回调地址
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    cancelUrl: string; // 取消回调地址
    /**
     * pendingUrl
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    pendingUrl: string; // pendingUrl
    /**
     * 失败回调地址
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    failureUrl: string; // 失败回调地址
    /**
     * 现金支付跳转地址
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    cashPayRedirectPath?: string; // 现金支付跳转地址
  }

  interface SignTokenParams {
    /**
     * 是否需要签约
     *
     * @type {(0 | 1)}
     * @memberof SignTokenParams
     */
    signUpFlag?: 0 | 1;
    /**
     * 签约id
     *
     * @type {string}
     * @memberof SignTokenParams
     */
    signUpId?: string;
    /**
     * 签约token
     *
     * @type {string}
     * @memberof SignTokenParams
     */
    channelDeviceFingerId: string;
    /**
     * 渠道sessionId
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    sessionId?: string; // 渠道sessionId
  }

  interface DeviceRiskParams {
    /**
     * riskified设备指纹
     *
     * @type {string}
     * @memberof DeviceRiskParams
     */
    riskifiedDeviceFingerprintID: string; // riskified设备指纹
    /**
     * cybersource设备指纹
     *
     * @type {string}
     * @memberof DeviceRiskParams
     */
    deviceFingerId: string; // cybersource 设备指纹
    /**
     * forter设备指纹
     *
     * @type {string}
     * @memberof DeviceRiskParams
     */
    forterDeviceFingerprintID: string; // forter设备指纹
    /**
     * forter id
     *
     * @type {string}
     * @memberof DeviceRiskParams
     */
    forterSiteId: string; // forter id
    /**
     * 同盾指纹
     *
     * @type {string}
     * @memberof DeviceRiskParams
     */
    blackbox: string; // 同盾指纹
  }

  interface LtspcCardPayParams {
    /**
     * 公钥id
     *
     * @type {string}
     * @memberof LtspcCardPayParams
     */
    publicKeyId: string;
    /**
     * 卡支付时此为ddc参数
     *
     * @type {string}
     * @memberof LtspcCardPayParams
     */
    sessionId?: string;
    /**
     * token id
     *
     * @type {string}
     * @memberof LtspcCardPayParams
     */
    tokenId: string;
    cardBin: string;
    cardLastFour: string;
    cvvHash: string,
    cvvCheck: string,
    GB_cybs_loaded?: 0 | 1, // 历史逻辑，在中间层上报异常
    installments: string | number;
    cardExpireYear?: string;
    cardExpireMonth?: string;
    cardHolderName?: string;
    cardNoHash?: string;
    cardExpireMonthHash?: string;
    cardExpireYearHash?: string;
    cardExpireYearShortHash?: string;
    cardType?: string;
    rememberCard?: number;
    cardNoCheck?: string;
    cardExpireMonthCheck?: string;
    cardExpireYearCheck?: string;
    cpfNumber?: string;
    autoBindCard?: number;
    // KR
    cardHolderBirthday?: string;
    // KR
    cardEnterpriseBusinessNo?: string;
    // KR
    cardPasswordFirstTwoDigit?: string;
  }

  interface SpecialChannelParams {
    /**
     * 银行code
     *
     * @type {string}
     * @memberof SpecialChannelParams
     */
    shopperBankCode: string; // 银行code
    /**
     * 银行name
     *
     * @type {string}
     * @memberof SpecialChannelParams
     */
    shopperBankName: string; // 银行name
    /**
     * 收集的手机号
     *
     * @type {string}
     * @memberof SpecialChannelParams
     */
    phone: string; // 收集的手机号
    /**
     * 收集的邮箱
     *
     * @type {string}
     * @memberof SpecialChannelParams
     */
    email: string; // 收集的邮箱
    /**
     * 验证码
     *
     * @type {string}
     * @memberof SpecialChannelParams
     */
    verificationCode?: string; // 验证
    applePayInfo?: string; // applePayInfo
    googlePayInfo?: string; // googlePayInfo
  }

  interface CustomerPrivateParams {
    // 卡bin冻结参数
    freezeParamsStr: string;
    checkoutScene: string;
  }

  interface UnifiedPayParams extends PayBrowserParams, PayCbUrlParams, SignTokenParams, DeviceRiskParams, Partial<LtspcCardPayParams>, Partial<CustomerPrivateParams>, Partial<SpecialChannelParams> {
    /**
     * 订单号
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    billno?: string; // 订单号
    /**
     * 关联订单号
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    relation_billno?: string; // 关联订单号
    /**
     * 支付方式编码
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    paymentCode: string; // 支付方式编码
    /**
     * 子订单号，用,分割
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    childBillnoList?: string; // 子订单号，用,分割
    /**
     * 页面类型
     *
     * @type {number | null}
     * @memberof UnifiedPayParams
     */
    pageType: number | null; // 页面类型
    /**
     * checkout类型 自定义参数
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    checkout_type?: string; // checkout类型
    /**
     * 支付神经元数据
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    neurPayId?: string;
    neurStep?: number;
    /**
     * 路由id
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    routeId?: string; // 路由id
  }

  interface ChannelUnifiedCbParams {
    token?: string;
    [key: string]: any;
  }

  interface UnifiedPayCbParams extends ChannelUnifiedCbParams {
    /**
     * 订单号
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    billno: string; // 订单号
    bill_no?: string; // 订单号老参数
    /**
     * 支付方式编码
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    paymentCode: string; // 支付方式编码
    /**
     * 支付神经元数据
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    neurPayId?: string;
    neurStep?: number;
    /**
     * 网关单号
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    gatewayNo?: string;
    /**
     * 支付网关单号
     *
     * @type {string}
     * @memberof UnifiedPayParams
     */
    gatewayPayNo?: string;
  }

  interface UnifiedPayCompleteParams {
    billno: string;
    gatewayPayNo: string;
    paymentCode: string;
    independThreedTraceID: string;
    verificationStep: string;
    verificationResult: string;
    independThreedsVerifyInfo: string;
    threeDVerifyResult: string;
    neurPayId: string;
    neurStep: string;
  }

  interface UnifiedPayRes {
    code?: number | string;
    info?: any;
  }

  interface UnifiedPayCbEvt {
    /**
     * 请求接口完成
     *
     * @type {boolean}
     * @memberof UnifiedPayEvt
     */
    onRequestCompleted?: (data: UnifiedPayRes) => void;
    /**
     * 接口正常响应，后端返回失败
     *
     * @type {boolean}
     * @memberof UnifiedPayEvt
     */
    onError?: (data: UnifiedPayRes) => void;
    /**
     * 接口正常响应，后端返回成功
     *
     * @type {boolean}
     * @memberof UnifiedPayEvt
     */
    onSuccess?: (data: UnifiedPayRes) => void;
    /**
     * 接口请求失败
     *
     * @type {boolean}
     * @memberof UnifiedPayEvt
     */
    onFail?: (err: any) => void;
    /**
     * 用户取消支付
     *
     * @memberof UnifiedPayCbEvt
     */
    onCancel?: () => void;
    /**
     * 接口请求前事件
     *
     * @type {boolean}
     * @memberof UnifiedPayEvt
     */
    initHandler?: () => void;
    /**
     * 用于弹窗支付时，支付暂停，需要用户手动点击按钮继续支付
     *
     * @memberof UnifiedPayCbEvt
     */
    onPayPaused?: () => void;
    /**
     * 用户弹窗支付时，支付继续回调事件
     *
     * @memberof UnifiedPayCbEvt
     */
    onPayContinued?: () => void;
  }

  interface UnifiedPayExtraInfo {
    countryCode: string;
    orderAmount: string;
    orderCurrency: string;
    sub_billnos?: any[];
    payment_method: string;
    billno: string;
    country_id: string;
    card_logo_list?: string[];
  }
  interface UnifiedPayOrgs<T = UnifiedPayParams | UnifiedPayCbParams> extends UnifiedPayCbEvt {
    params: T,
    extraInfo?: UnifiedPayExtraInfo;
  }

  // type Mutable<T> = {
  //   -readonly [P in keyof T]: T[P]
  // }

  // interface Fn<T = any, R = T> {
  //   (...arg: T[]): R
  // }

  // interface PromiseFn<T = any, R = T> {
  //   (...arg: T[]): Promise<R>
  // }

  // type FuncObject<T extends string = string> = Record<T, Fn | PromiseFn>

  // interface GetterEnvs {
  //   get isLogin(): boolean
  // }

  // interface Router {
  //   readonly push: (to: Route, force?: boolean) => void
  //   readonly replace: (to: Route, force?: boolean) => void
  //   readonly back: (force?: boolean) => void
  // }

  // type Route = string | { path: string; query?: Record<string, any> }

  // type BasicLangKeys = 'yes' | 'no' | 'ok' | 'cancel' | 'confirm'
  // type BasicLangs = Record<BasicLangKeys, string>

  // interface AppConfigs {
  //   readonly $language: BasicLangs
  //   readonly $envs: Pick<Envs, 'project' | 'langPath' | 'lang' | 'currency'>
  //   readonly vueVersion: 2 | 3
  //   readonly $getters: GetterEnvs
  //   readonly $schttp: <T>(config: AxiosRequestConfig) => AxiosPromise<T>
  //   readonly $router: Router
  // }

  // interface RequestConfigMixins {
  //   readonly baseURL?: AxiosRequestConfig['baseURL']
  //   readonly method?: Method
  //   readonly M_params?: AxiosRequestConfig['params']
  //   readonly M_data?: AxiosRequestConfig['data']
  //   readonly M_headers?: AxiosRequestConfig['headers']
  //   readonly O_params?: AxiosRequestConfig['params']
  //   readonly O_data?: AxiosRequestConfig['data']
  //   readonly O_headers?: AxiosRequestConfig['headers']
  // }

  // interface DataSourceInterface<T, U> {
  //   readonly type?: T
  //   readonly modified?: {
  //     readonly [P in keyof U]?: RequestConfigMixins
  //   }
  //   readonly overrides?: {
  //     readonly [P in keyof U]?: U[P]
  //   }
  // }

  // type AddThis<T extends Record<string, Fn | PromiseFn>> = {
  //   readonly [P in keyof T]: (
  //     this: AppConfigs,
  //     ...arg: Parameters<T[P]>
  //   ) => ReturnType<T[P]>
  // }

  // interface AnalysisSourceInterface<T extends string> {
  //   readonly handler?: Fn | PromiseFn
  //   readonly overrides?: Partial<AnalysisFuncs<T>>
  //   readonly beforeSend?: Partial<AnalysisFuncs<T>>
  // }

  // type AnalysisFuncs<T extends string> = FuncObject<T>

  // interface EventProxyHostInterface<T extends string = string>
  //   extends EventProxyInterface<T> {
  //   readonly collections: Record<string, Partial<FuncObject<T>>>
  //   trigger<K extends T>(event: K, params?: any): void
  // }

  // interface EventProxyInterface<T extends string> {
  //   subscribe(compName: string, funcs: Partial<FuncObject<T>>): void
  //   describe(compName: string): void
  // }
}

// export declare namespace Manager {
//   type ToFunc<T> = {
//     [P in keyof T]: () => T[P]
//   }

//   interface InitConfigs {
//     langs: Libs.AppConfigs['$language']
//     envs: Libs.Envs
//     getters: ToFunc<Libs.GetterEnvs>
//     schttp: Libs.AppConfigs['$schttp']
//     router?: RouterInterface
//   }

//   interface RouterInterface {
//     push(url: string): void
//     replace(url: string): void
//     back(): void
//     resolve(location: string): { matched?: any[]; route?: { matched: any[] } }
//   }

//   type ProxyHostNames = ''
//   type ProxyHostTrigger = Pick<Libs.EventProxyHostInterface, 'trigger'>
// }

export type ApplePayButtonLocale = 'ar-AB' | 'ca-ES' | 'cs-CZ' | 'da-DK' | 'de-DE' | 'el-GR' | 'en-AU' | 'en-CA' | 'en-GB' | 'en-US' | 'es-ES' | 'es-MX' | 'fi-FI' | 'fr-CA' | 'fr-FR' | 'he-IL' | 'hr-HR' | 'hu-HU' | 'id-ID' | 'it-IT' | 'ja-JP' | 'ko-KR' | 'ms-MY' | 'nb-NO' | 'nl-NL' | 'pl-PL' | 'pt-BR' | 'pt-PT' | 'ro-RO' | 'ru-RU' | 'sk-SK' | 'sv-SE' | 'th-TH' | 'tr-TR' | 'uk-UA' | 'vi-VN' | 'zh-CN' | 'zh-HK' | 'zh-TW'
export type ApplePayButtonType = 'add-money' | 'buy' | 'book' | 'check-out' | 'continue' | 'contribute' | 'donate' | 'order' | 'pay' | 'plain' | 'reload' | 'rent' | 'top-up' | 'select' | 'set-up' | 'subscribe' | 'tip'

export type ChannelSDkOptions = {
  preloadSDK: boolean
}

/**
 *
 *
 * @interface PayBrowserParams
 */
export interface PayBrowserParams {
  /**
   * 浏览器语言
   *
   * @type {string}
   * @memberof PayBrowserParams
   */
  device_language: string;
  /**
   * 浏览器ua
   *
   * @type {string}
   * @memberof PayBrowserParams
   */
  userAgent: string;
  /**
   * accept
   *
   * @type {string}
   * @memberof PayBrowserParams
   */
  acceptHeader?: string;
  /**
   * colorDepth
   *
   * @type {string}
   * @memberof PayBrowserParams
   */
  colorDepth?: number | string;
  screenHeight: string;
  screenWidth: string;
  timeZoneOffset: string;
  javaEnabled?: string | number;
  javaScriptEnabled?: string | number;
  language: string;
}

/**
 * 需传给南京的回调url等参数
 *
 * @export
 * @interface PayCbUrlParams
 */
export interface PayCbUrlParams {
  /**
   * 回调地址
   *
   * @type {string}
   * @memberof UnifiedPayParams
   */
  callbackUrl: string; // 回调地址
  /**
   * 取消回调地址
   *
   * @type {string}
   * @memberof UnifiedPayParams
   */
  cancelUrl: string; // 取消回调地址
  /**
   * pendingUrl
   *
   * @type {string}
   * @memberof UnifiedPayParams
   */
  pendingUrl: string; // pendingUrl
  /**
   * 失败回调地址
   *
   * @type {string}
   * @memberof UnifiedPayParams
   */
  failureUrl: string; // 失败回调地址
  /**
   * 现金支付跳转地址
   *
   * @type {string}
   * @memberof UnifiedPayParams
   */
  cashPayRedirectPath?: string; // 现金支付跳转地址
}

// CHECKOUT_TYPE_LIST: [
//   'normal', // 普通商品一次下单
//   'normal_again', // 普通商品二次下单
//   'giftcard', // 礼品卡一次下单
//   'giftcard_again', // 礼品卡二次下单
//   'buyprime', // 付费会员一次下单
//   'buyprime_again', // 付费会员二次下单
//   'xtra', // 超省卡一次下单
//   'xtra_again', // 超省卡二次下单
//   'ocp', // 一键购
//   'front_token', // token前置
//   'buynow', // 立即购一次下单
//   'buynow_again', // 立即购二次下单
// ],

/**
 * 支付商品类型
 *
 * @export
 * @enum {string}
 */
export enum PRODUCT_TYPE {
  /**
   * 普通商品
   */
  NORMAL = 'normal',
  /**
   * 礼品卡
   */
  GIFT_CARD = 'gift_card',
  /**
   * 付费会员
   */
  PAID_MEMBERSHIP = 'paid_membership',
  /**
   * 超省卡
   */
  PAID_SHEIN_SAVER = 'paid_shein_saver',
  /**
   * 一键购
   */
  OCP_CLICK_PAY = 'one_click_pay'
}

/**
 * 支付场景
 *
 * @export
 * @enum {string}
 */
export enum CHECKOUT_TYPE {
  /**
   * 普通商品一次下单
   */
  NORMAL = 'normal',
  /**
   * 普通商品二次下单
   */
  NORMAL_AGAIN = 'normal_again',
  /**
   * 礼品卡一次下单
   */
  GIFTCARD = 'giftcard',
  /**
   * 礼品卡二次下单
   */
  GIFTCARD_AGAIN = 'giftcard_again',
  /**
   * 付费会员一次下单
   */
  BUYPRIME = 'buyprime',
  /**
   * 付费会员二次下单
   */
  BUYPRIME_AGAIN = 'buyprime_again',
  /**
   * 超省卡一次下单
   */
  XTRA = 'xtra',
  /**
   * 超省卡二次下单
   */
  XTRA_AGAIN = 'xtra_again',
  /**
   * 一键购
   */
  OCP = 'ocp',
  /**
   * token前置
   */
  FRONT_TOKEN = 'front_token', // token前置
  /**
   * 卡前置
   */
  FRONT_PAY = 'front_pay', // 卡前置
  /**
   * 收银台
   */
  CASHIER = 'cashier', // 收银台
  /**
   * 收银台
   */
  CASHIER_AGAIN = 'cashier_again', // 收银台单后场景

  /**
   * 二次下单类型
   */
  AGAIN_TYPE = 'again',
}

export enum PAYMENT_SCENE_TYPE {
  /**
   * 普通场景
   */
  NORMAL = 'normal',
  /**
   * token前置
   */
  FRONT_TOKEN = 'front_token', // token前置
  /**
   * 卡前置
   */
  FRONT_PAY = 'front_pay', // 卡前置
}

/**
* 页面名称
*
* @enum {string}
*/
export enum PAGE_NAME {
  /**
   * 下单页
   */
  PAGE_CHECKOUT = 'page_checkout',
  /**
   * 二次下单页
   */
  PAGE_CHECKOUT_AGAIN = 'page_checkout_again',
  /**
   * 订单详情页
   */
  PAGE_ORDER_DETAIL = 'page_order_detail',
  /**
   * 订单列表页
   */
  PAGE_ORDER_LIST = 'page_order_list',
}

export enum PAYMENT_ACTION_TYPE {
  /**
   * 卡支付失败
   */
  CARD = 'card',
  /**
   * 跳转第三方支付
   */
  REDIRECT_THIRD = 'redirect_third',
  /**
   * 跳转自有支付页面
   */
  REDIRECT_SELF_PAGE = 'pre_auth_complete',
  /**
   * paypal sdk
   */
  PAYPAL_SDK = 'paypal_sdk',
  /**
   * venmo sdk
   */
  VENMO_SDK = 'venmo_sdk',
  /**
   * apple pay
   */
  APPLE_PAY = 'apple_pay',
  /**
   * google pay
   */
  GOOGLE_PAY = 'google_pay',
  /**
   * 货到付款
   */
  COD = 'cod',
  /**
   * 调用渠道sdk支付方式（除pp及venmo之外的）
   */
  CHANNEL_SDK = 'channel_sdk',
  /**
   * 原页面弹窗支付
   */
  ORIGIN_PAGE_DIALOG = 'origin_page_dialog',
}

export enum FAILURE_TYPE {
  /**
   * 前端代码逻辑报错
   */
  WEB = 'web',
  /**
   * sdk报错
   */
  SDK = 'sdk',
  /**
   * 接口报错
   */
  API = 'api'
}

export interface ApolloInfo {
  googlePayCodeList: any;
  applePayCodeList: any;
  applePayConfig: any;
  paymentCardLogoConfig: any;
  googleReadyToPayRequest: any;
  googlePayConfig: any;
  payConfigSwitch: any;
  PRE_ROUTING_PAYMENT_METHODS: string[];
  DLOCAL_PAYMENT_METHODS: string[];
  FastUnload: any;
}

export interface ChannelSessionInfo {
  payMethod: string;
  sessionId: string;
  clientToken: string;
  paymentMethodCategory: string;
  scale: number;
  profileId: null;
  purchaseData?: any;
}

declare global {
  interface Window {
    BS_Checkstand: typeof CheckstandManager;
    BS_Checkstand_Instance: CheckstandManager;
  }
}
