import { useEventBus } from './useEventBus'
import { type Trade_PayLibs } from '@shein-aidc/types-trade'
import type { PayPalButtonStatus } from '../checkstand/tools/PaypalGaManager'

export const bsPayEventBus = {
  updatePaymentInstance: useEventBus<{
    paymentCode: string;
    clientToken?: string;
    currency?: string;
    profileId?: string;
    paymentMethodCategory?: string | null;
    [key: string]: any;
  }>('update-payment-instance'),
  updatePaySessionInfo: useEventBus<{
    scene: string;
    paySessionInfo: any;
  }>('update-pay-session-info'),
  updatePaymentInfo: useEventBus<{
    paySessionInfo?: any,
    currency?: string,
    countryCode?: string,
  }>('update-payment-info'),
  winClosed: useEventBus<{
    winInstance: Window & typeof globalThis;
  }>('win-closed'),
  winResult: useEventBus<Trade_PayLibs.OutsideBsResult>('win-result'),
  paypalLoadStatus: useEventBus<{
    status: PayPalButtonStatus;
  }>('paypal-load-status'),
}

type BsPayEventBusKeys = keyof typeof bsPayEventBus

export const useBsPayEventBus = () => {
  const teardown = (key?: BsPayEventBusKeys) => {
    if (key) {
      bsPayEventBus[key].reset?.()
      return
    }
    for (const key in bsPayEventBus) {
      bsPayEventBus[key].reset?.()
    }
  }

  return {
    teardown,
  }
}

