import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { debuggerLog } from '../../utils'
import { MonitorReportAbnormal, MonitorReportBusiness } from '../helpers/MonitorReport'
import { PAYMENT_ACTION_TYPE } from '../../../types'

interface ICodConfig {
  codFreeShow?: string
}

// interface IOrderInfo {
//   billno: string
//   sub_billnos?: { sub_billno: string }[]
//   relation_billno: string
//   country_id: string
//   totalAmountWithSymbol: string
// }

interface ICodVerifyRes {
  code: string
  info: {
    certificationFree: number
  }
}

export interface CodPayManagerConstructor extends BasicPayManagerConstructor { }

export class CodPayManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = 'cod'

  public paymentCode: string = CodPayManager.PAYMENT_CODE

  private static payConfig: ICodConfig = {}

  constructor(params: CodPayManagerConstructor) {
    super(params)
  }

  private static openDialogMethod: ((params: any) => void) | null = null

  private static showBackTipsMethod: (() => void) | null = null

  private static fetchBackTipsData: ((params: any) => void) | null = null

  private _sendReport = () => { }

  private async checkCodNeedVerify (params: { billno: string }) {
    const result = await this.appConfigs.$schttp<ICodVerifyRes>({
      baseURL: this.appConfigs.$envs?.langPath,
      url: '/api/pay/cod/queryNeedVerify/get',
      data: { billno: params.billno },
      method: 'POST',
    })
    return result.data || result
  }

  public static initCod<T extends ICodConfig> (codConfig: T) {
    debuggerLog('CodPayManager initCod', codConfig)
    const { codFreeShow } = codConfig
    this.payConfig.codFreeShow = codFreeShow
  }

  public static bindCodFreeVerifyDialogMethod (method: ((params: any) => void) | null) {
    debuggerLog('CodPayManager bindCodFreeVerifyDialogMethod')
    this.openDialogMethod = method
  }

  public static bindCodBackTipsMethod (params: { showMethod: (() => void) | null, fetchData: ((params: any) => void) | null }) {
    debuggerLog('CodPayManager bindCodFreeVerifyCancelMethod', params)
    const { showMethod, fetchData } = params
    this.showBackTipsMethod = showMethod
    this.fetchBackTipsData = fetchData
  }

  public createPayment (): Promise<any> {
    // this.paymentLoadingAction?.({ show: true })
    const { orderInfo = {} } = this.payData || {}
    // 单个子订单场景下以G订单号请求；多个订单场景下以U订单号请求
    let validBillno = orderInfo?.relation_billno
    // 兼容 目前只有一个子订单时不会返回sub_billnos
    if (orderInfo?.sub_billnos?.length === 1 && orderInfo.sub_billnos[0].sub_billno) {
      validBillno = orderInfo.sub_billnos[0].sub_billno
    }
    // if (order?.sub_billnos?.length && order.sub_billnos.length > 1) {
    //   validBillno = order?.relation_billno
    // }
    return this.checkCodNeedVerify({ billno: validBillno }).then((res) => {
      if (res.code == '0' && res.info) {
        const { codFreeShow } = CodPayManager.payConfig
        const { certificationFree } = res.info
        if (certificationFree == 1 && codFreeShow && codFreeShow !== 'Hide') {
          if (codFreeShow === 'Show1') {
            CodPayManager.openDialogMethod?.({
              needCountdown: false,
              order: orderInfo,
              onValidateFail: this.onFail?.bind?.(this),
              onValidateSuccess: this.onSuccess?.bind?.(this),
              onCreate: () => {
                CodPayManager.fetchBackTipsData?.call?.(this, orderInfo)
              },
              onClose: (params) => {
                const { showBackTips = false } = params || {}
                if (showBackTips) {
                  CodPayManager.showBackTipsMethod?.apply?.(this)
                } else {
                  this.onCancel?.()
                }
                MonitorReportBusiness.metric({
                  scene: 'cod_pay_free_verify_cancel',
                  extraTags: {
                    payment_code: this.paymentCode,
                  },
                  extraParams: {
                    billno: validBillno,
                    codFreeShow: CodPayManager.payConfig.codFreeShow,
                    payment_action_type: PAYMENT_ACTION_TYPE.COD,
                  },
                })
              },
              onStartValidate: () => {
                this.paymentLoadingAction?.({ show: true })
              },
            })
          } else if (codFreeShow === 'Show2') {
            CodPayManager.openDialogMethod?.({
              needCountdown: true,
              order: orderInfo,
              onValidateFail: this.onFail?.bind?.(this),
              onValidateSuccess: this.onSuccess?.bind?.(this),
              onCreate: () => {
                CodPayManager.fetchBackTipsData?.call?.(this, orderInfo)
              },
              onClose: (params) => {
                const { showBackTips = false } = params || {}
                if (showBackTips) {
                  CodPayManager.showBackTipsMethod?.apply?.(this)
                } else {
                  this.onCancel?.()
                }
              },
              onStartValidate: () => {
                this.paymentLoadingAction?.({ show: true })
              },
            })
            // this.onPayPaused?.()
          }
          // this.paymentLoadingAction?.({ show: false })
        } else {
          this.paymentLoadingAction?.({ show: false })
          // TODO 跳转到cod验证页面
          window.location.href = `${this.appConfigs.$envs?.langPath}/pay/cod?billno=${validBillno}&country_id=${orderInfo.country_id}`
        }
      } else {
        this.paymentLoadingAction?.({ show: false })
        this.onError?.(res)
        MonitorReportAbnormal.metric({
          scene: 'cod_pay_get_verify_info_error',
          extraTags: {
            payment_code: this.paymentCode,
            status_code: res.code || '',
          },
          extraParams: {
            billno: validBillno,
            codFreeShow: CodPayManager.payConfig.codFreeShow,
            error_stack: JSON.stringify(res || {}),
            payment_action_type: PAYMENT_ACTION_TYPE.COD,
          },
        })
      }
      return res
    }).catch((err) => {
      this.paymentLoadingAction?.({ show: false })
      this.onError?.(err)
      MonitorReportAbnormal.metric({
        scene: 'cod_pay_get_verify_info_catch_error',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: {
          billno: validBillno,
          codFreeShow: CodPayManager.payConfig.codFreeShow,
          error_stack: JSON.stringify(err || {}),
          error_msg: err?.message || '',
          payment_action_type: PAYMENT_ACTION_TYPE.COD,
        },
      })
    })
  }
}
