export const PRE_WIN_INSTANCE_HTML = `<!DOCTYPE html>
    <html>
    <head>
      <meta charset="UTF-8">
    </head>
    <style>
      .loading__circular-svg{width:80px;height:80px;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transform-origin:center
      center;-ms-transform-origin:center center;transform-origin:center center;-webkit-animation:loading-rotate
      1s linear infinite;animation:loading-rotate 1s linear infinite}.loading__circular-path{stroke:#222222;stroke-dasharray:1,200;stroke-linecap:round;-webkit-animation:loading-stroke
      1s linear infinite;animation:loading-stroke 1s linear infinite}@keyframes
      loading-rotate{0%{-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg)}40%{-webkit-transform:rotate(-35deg);-ms-transform:rotate(-35deg);transform:rotate(-35deg)}100%{-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg)}}@keyframes
      loading-stroke{0%{stroke-dasharray:11.0528,200}40%{stroke-dasharray:93.9488,200}100%{stroke-dasharray:11.0528,200}}
    </style>
    
    <body>
    <svg viewBox="0 0 48 48" class="loading__circular-svg">
      <circle cx="24" cy="24" r="22" fill="none" stroke-width="2" class="loading__circular-path">
      </circle>
    </svg>
    
    </body>
    
    </html>`
