import { reactive } from 'vue'
import type { Libs, LibsComponentLanguages } from '../types'

export async function getLangs<T extends string>(langsMap: Record<T, string>, $schttp: Libs.AppConfigs['$schttp']): Promise<Record<T, string>> {
    const result = await $schttp<any>({
        url: '/system/configs/multi_language',
        method: 'POST',
        data: { languageKeys: Object.values(langsMap) },
    })
    const data = result.data?.info?.result

    return Object.keys(langsMap).reduce<any>((curr, next) => {
        curr[next] = data?.[langsMap[next]] || ''
        return curr
    }, {} as T)
}

interface getLibsLangsParams {
    languages?: Partial<LibsComponentLanguages>
    langPackages: Partial<LibsComponentLanguages>
    appConfigs: Libs.AppConfigs
    fromInitialize?: boolean
    baseURL?: string
}
/**
 * ## 此方法的根本目的是为了杜绝客户端多语言请求随业务发展裂变增多
 * ## changelog：
 * languages 可单独使用，不再强制 langPackages 校验 languages，降低接入成本。异常传参通过命名空间定位业务排查;   
 * 不要当成组件的 props 来使用非标准 lang，严查;   
 *
 * #1 核心组件
 * ---------------------
 * ## client：
 * 1.1 基本固化需要的多语言 e.g. 头尾组件、业务首屏组件；   
 * ```
 * LibsManager.initialize({ languages: {} })
 * ```
 * 1.2 非首屏，请求提前批。业务自己评估；   
 * 单页应用放置公共弹窗等，多页应用可完全拉取页面其他所有（评估好大小与切割点（2.1））；   
 * 与 languages 可同时传参不冲突，langPackages 作为请求补全；   
 * ```
 * const json1 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * const json2 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * LibsManager.initialize({ langPackages: { ...json1, ...json2 } })
 * ```
 *
 * ## server：
 * 1.3 服务端渲染
 * ```
 * // step1
 * // 各自链路把需要的所有 langPackages 一次性调用；   
 * // baseURL 传入 bff-host；   
 * const json1 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * const json2 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * const [libsLangs] = fsRunnerExecutor({
 *   req, res, runners: [
 *     async appConfigs => {
 *       return await getLibsLangs({ langPackages: { ...json1, ...json2 }, appConfigs, baseURL }).request
 *     }
 *   ]
 * })
 * ```
 *
 * fsRunnerExecutor 是实现了 schttp 接口的 node 初始化环境；   
 * 本质是带有 requestInServer: true 标记的 LibsManager.initialize, 以区分是否为 ssr 阶段（是则不允许请求）；   
 * 请求首屏数据不限于多语言，参考 pwa；   
 *
 * ```
 * // step2
 * // ssr 注入 - businessLibInitializeForSSR 阶段传入
 * LibsManager.initialize({ languages: context.libsLangs })
 * ```
 * ```
 * // step3
 * // client 接管 - businessLibInitializeForCSR 阶段注入 （1.1）
 * LibsManager.initialize({ languages: contextForSSR.libsLangs })
 * ```
*
* #2 非核心组件
* ---------------------
* ## client only：
 * 选择合适时机，自行按需管理
 * e.g. 非首屏大板块如交互拉起的全屏弹窗，批量拉取内部所有涉及的组件多语言；   
 * 此方法会排除已请求过的命名空间的 langs；   
 * 2.1
 * ```
 * import { injectLanguages } from '@shein-aidc/bs-sdk-libs-manager'
 * const json1 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * const json2 = require('@shein-aidc/bs-xxx/lang/xx.json')
 * await injectLanguages({ langPackages: { ...json1, ...json2 } }).request
 * ```
 *
 * #3 组件闭环维护（not recommend）
 * ---------------------
 * 此方案本质是将多语言视作基本组件数据来处理（同老现状）；   
 * 此方案会把 json 文件打进组件包，也会可预见演化为应用方大量 language 请求；   
 * 纯客户端巨型业务组件才有需要考虑是否使用此方案（满足#2 / lang 量级足够大...）；   
 * ```
 * // file：/dataSource/sources/xx.ts
 * import json from '@packages/bs/xxx/lang/xx.json'
 * export const injectLanguages: DS_XXX.APIS_THIS['injectLanguages'] = async function () {
 *   // 此返回值组件开发者应当忽略，见下
 *   return await getLibsLangs({ langPackages: { ...json }, appConfigs: this }).request
 * }
 * ```
 * client   
 * 自动注入 $language，组件应当始终从 $language 取数，不要独立维护返回值这份 langs   
 * server   
 * fsRunnerExecutor 执行 fsDataRunner 同样会将结果带出，接着按核心流程（1.3）step2 step3 走   
 */
export function getLibsLangs({ languages = {}, langPackages, appConfigs, fromInitialize = false, baseURL }: getLibsLangsParams): { request?: Promise<LibsComponentLanguages>, langPool: LibsComponentLanguages } {
    const { $schttp, $envs, $language } = appConfigs
    const langPool = languages === $language ? languages : reactive({...languages})
    const autoAssign = typeof window !== 'undefined' && !fromInitialize
    const makeRequest = typeof window !== 'undefined' || !!$envs.requestInServer && !fromInitialize


    const langPoolForRequest = Object.keys(langPackages).reduce<any[]>((langPoolForRequest, compName) => {
        if (languages[compName]) return langPoolForRequest
        const keyPackages = langPackages[compName]
        const defaultPool = Object.keys(keyPackages).reduce((newPool, rename) => {
            newPool[rename] = ''
            return newPool
        }, {})
        langPool[compName] = reactive(defaultPool)
        langPoolForRequest.push([langPool[compName], keyPackages])

        return langPoolForRequest
    }, [])

    const languageKeys = langPoolForRequest.map(([, keyPackages]) => Object.values(keyPackages)).flat()
    if (!languageKeys.length || !makeRequest) {
        return { langPool } as { langPool: LibsComponentLanguages }
    }

    const headers = {}
    if ($envs.appLanguage) {
        headers['appLanguage'] = $envs.appLanguage
    }

    const request = $schttp<any>({ url: '/system/configs/multi_language', method: 'POST', headers, data: { languageKeys }, baseURL })
        .then(_ => _.data?.info?.result)
        .then(allLangs => {
            langPoolForRequest.forEach(([langs, keyPackages]) => {
                Object.keys(keyPackages).forEach(rename => {
                    langs[rename] = allLangs?.[keyPackages[rename]] || ''
                })
            })
            return langPool
        })

    autoAssign && Object.assign($language, langPool)
    return { request, langPool } as { request: Promise<LibsComponentLanguages>, langPool: LibsComponentLanguages }
}
