/* global braintree */
import { loadChannelSdk } from '.'
import { debuggerLog } from '../utils'

export default class PayPalCard {
  completeHandle: any = function () {}

  errorHandle: any = function () {}

  isValid = false
  paymentData = {}
  inChallenge = false //是否正在挑战
  threeDSecureInstance = null
  paramList = {} // 参数列表
  necessaryOrderInfo = {} // 必要的订单信息

  constructor(
    options = {
      completeHandle: function () { },
      errorHandle: function () { } as any,
    },
  ) {
    if (typeof window === 'undefined') return

    this.completeHandle = options.completeHandle
    this.errorHandle = options.errorHandle
    this.initSDK()
  }

  async initSDK() {
    return await loadChannelSdk.initPPCardSdk()
  }

  initPaypalDropIn({ paramList, totalPriceAmount = '', email = '' }) {
    this.paramList = paramList || {}
    this.necessaryOrderInfo = { totalPriceAmount, email }
    this.inChallenge = true
    // https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side/javascript/v3
    // @ts-ignore
    braintree?.client?.create?.({
      authorization: paramList?.clientToken,
    }).then(clientInstance => {
      debuggerLog('paypal create clientInstance: ', clientInstance)
      // window.payEventCenter?.$emit?.("show-loading", false);
      // @ts-ignore
      return braintree.threeDSecure.create({
        version: 2, // Will use 3DS2 whenever possible
        client: clientInstance,
      })
    }).then(this.verifyCard.bind(this)).catch(error => {
      debuggerLog('paypal create error: ', error)
      this._errorHandle()
      // window.payEventCenter?.$emit?.('show-loading', false)
    })
  }

  useThreeDSecureParameters({
    cardBin, cardNonce,
  }) {
    //const order = orderPlaceVue?.$data?.order || {}
    const { totalPriceAmount, email } = (this.necessaryOrderInfo || {}) as any
    return {
      amount: totalPriceAmount || '',
      email: email || '',
      nonce: cardNonce,
      bin: cardBin,
      onLookupComplete(data, next) {
        debuggerLog('paypal onLookupComplete: ', data)
        next()
      },
    }
  }

  verifyCard(threeDSecureInstance) {
    if (!threeDSecureInstance) {
      return this._errorHandle()
    }
    const { cardBin, cardNonce } = (this.paramList || {}) as any
    const threeDSecureParameters = this.useThreeDSecureParameters({ cardBin, cardNonce })
    debuggerLog('paypal verifyCard threeDSecureParameters: ', threeDSecureParameters, threeDSecureInstance)
    threeDSecureInstance.verifyCard(threeDSecureParameters).then((response = {}) => {
      debuggerLog('paypal verifyCard response: ', response)
      this.successHandle(response)
    }).catch(error => {
      debuggerLog('paypal verifyCard error: ', error)
      this._errorHandle()
    })
  }

  /**
   * 失败处理
   */
  _errorHandle() {
    this.inChallenge = false
    this.errorHandle()
  }

  /**
   * 成功处理逻辑
   *
   * @param {*} response 渠道返回的响应数据
   */
  successHandle(response) {
    this.inChallenge = false
    let threeDVerifyResult = ''
    try {
      threeDVerifyResult = JSON.stringify(response)
    } catch (e) {
      threeDVerifyResult = ''
    }
    // payEventCenter.$emit('show-loading', true) // 开启loading

    this.completeHandle({ threeDVerifyResult })
  }
}
