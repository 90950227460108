
import { getDeviceInfo, debuggerLog } from '../utils'
import { mergeQueryString } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { NoCardRoutepayPreRouting, type PreCardRoutingParams } from './helpers/NoCardRoutepayPreRouting'
import { ApplePayManager } from './tools/ApplePayManager'
import { MonitorReportAbnormal, MonitorReportBusiness } from './helpers/MonitorReport'

import type { Trade_PayLibs, Trade_PayToolKit } from '@shein-aidc/types-trade'
import { type ApolloInfo, type ChannelSessionInfo } from '../../types'
import { type StoreInfo } from '../store'
import { type AbtPosKeysTypes } from '../constants'
import { DLOCAL_PAYMENT_METHODS, PRE_ROUTING_PAYMENT_METHODS } from '../config'
import encryptParams from '../utils/encrptParams'

interface CheckstandModalSetupExtraParams extends
  Trade_PayLibs.C2pPayParams,
  Trade_PayLibs.SignTokenParams,
  Trade_PayLibs.LtspcCardPayParams,
  Trade_PayLibs.CustomerPrivateParams,
  Trade_PayLibs.SpecialChannelParams,
  Trade_PayLibs.PayCbUrlParams {
    clientToken?: string;
    routeId?: Trade_PayLibs.UnifiedPayParams['routeId'];
    channelSessionInfo?: ChannelSessionInfo;
    cvv?: string;
    cardNumber?: string;
    sortedPriceInfo?: Trade_PayToolKit.SortedPriceItem[];
    email?: string;
    paymentCode?: string; // 实际路由到的支付方式
    expireMonth?: string; // 有效期月
    expireYear?: string; // 有效期年
    vipOrderSource?: number;
  }

interface ChannelExtraInfo {
  clientToken?: string;
  countryCode?: string;
  currencyCode?: string;
  countryId?: string;
  orderAmount?: string;
  sub_billnos?: string[];
  card_logo_list?: string[];
  useSync?: boolean;
  supportedNetworks?: string[];
  channelSessionInfo?: ChannelSessionInfo;
  sortedPriceInfo?: Trade_PayToolKit.SortedPriceItem[];
  email?: string;
  // noCardRoutepayAction?: string;
}

export interface CheckstandModalSetup {
  orderInfo: any;
  paymentInfo: Trade_PayToolKit.SelectedPaymentInfo;
  extraParams: Partial<CheckstandModalSetupExtraParams>;
  bsUtils: Trade_PayLibs.OutsideBsUtils;
  encryptConfig?: StoreInfo['encryptConfig'];
  bsPayConfig?: Trade_PayLibs.BsPayConfig;
  abtInfo: Record<AbtPosKeysTypes, Trade_PayToolKit.AbtInfo>;
  apolloInfo: ApolloInfo;
}

export interface CheckstandModalData {
  unifiedPayParams: Trade_PayLibs.UnifiedPayParams;
  channelExtraInfo: ChannelExtraInfo;
  paymentCode: string;
  orderInfo: any;
  preRoutingInfo: any;
}

export class CheckstandModal {

  public unifiedPayParams: Trade_PayLibs.UnifiedPayParams = {} as Trade_PayLibs.UnifiedPayParams

  public channelExtraInfo: ChannelExtraInfo = {}

  public orderInfo: any = {}

  public paymentCode: string = ''

  public preRoutingInfo: any = {} as any

  private bsUtils: Trade_PayLibs.OutsideBsUtils = {} as Trade_PayLibs.OutsideBsUtils

  private encryptConfig: StoreInfo['encryptConfig'] = {} as StoreInfo['encryptConfig']

  private appConfigs = useAppConfigs()

  private useInline: boolean = false

  // private static instance: CheckstandModal

  constructor() { }

  private get data(): CheckstandModalData {
    return {
      unifiedPayParams: this.unifiedPayParams,
      paymentCode: this.paymentCode,
      channelExtraInfo: this.channelExtraInfo,
      orderInfo: this.orderInfo,
      preRoutingInfo: this.preRoutingInfo,
    }
  }

  private formatCbUrlParams = ({ extraParams, apolloInfo, unifiedPayParams, bsPayConfig }: Pick<CheckstandModalSetup, 'extraParams' | 'apolloInfo' | 'bsPayConfig'> & { unifiedPayParams: Trade_PayLibs.UnifiedPayParams }) => {
    const dlocalPaymentMethods = apolloInfo?.DLOCAL_PAYMENT_METHODS?.length ? apolloInfo?.DLOCAL_PAYMENT_METHODS : DLOCAL_PAYMENT_METHODS
    const SITE_URL = `${(this.appConfigs.$envs as any).host}${this.appConfigs.$envs.langPath}`
    const cbUrl = '/pay/result/unifiedCb'
    const { paymentCode, tokenId } = unifiedPayParams || {}
    const billno = unifiedPayParams.relation_billno || unifiedPayParams.billno

    const oldCheckoutType = this.getOldCheckoutType(bsPayConfig?.checkoutType || '')

    let callbackUrl,
      failureUrl = extraParams.failureUrl || '',
      cancelUrl = extraParams.cancelUrl || '',
      pendingUrl
      // cashPayRedirectPath = ''

    callbackUrl = pendingUrl = mergeQueryString({
      url: SITE_URL + cbUrl,
      mergeObj: {
        bill_no: billno,
        ...(oldCheckoutType ? { checkout_type: oldCheckoutType } : {}),
      },
    })

    if (dlocalPaymentMethods?.includes?.(paymentCode)) {
      // mergeQueryString
      callbackUrl = pendingUrl = mergeQueryString({
        url: SITE_URL + '/transform/middlePage',
        mergeObj: {
          callbackFormUrl: encodeURIComponent(SITE_URL + cbUrl),
          bill_no: billno,
          ...(oldCheckoutType ? { checkout_type: oldCheckoutType } : {}),
        },
      })
      failureUrl = mergeQueryString({
        url: SITE_URL + '/transform/middlePage',
        mergeObj: {
          failureFormUrl: encodeURIComponent(failureUrl),
          bill_no: billno,
        },
      })
      cancelUrl = mergeQueryString({
        url: SITE_URL + '/transform/middlePage',
        mergeObj: {
          cancelFormUrl: encodeURIComponent(cancelUrl),
          bill_no: billno,
        },
      })
    }

    // useInline场景特殊处理
    if (this.useInline) {
      callbackUrl = mergeQueryString({
        url: SITE_URL + '/pay/result/bs/inline/callback',
        mergeObj: {
          billno: billno,
          bs_scene: 'checkout',
        },
      })
      cancelUrl = mergeQueryString({
        url: SITE_URL + '/pay/result/bs/inline/cancel',
        mergeObj: {
          billno: billno,
          bs_scene: 'checkout',
          // cancelFormUrl: encodeURIComponent(cancelUrl),
        },
      })
      if (dlocalPaymentMethods?.includes?.(paymentCode)) {
        callbackUrl = pendingUrl = mergeQueryString({
          url: SITE_URL + '/transform/middlePage',
          mergeObj: {
            callbackFormUrl: encodeURIComponent(callbackUrl),
            bill_no: billno,
          },
        })
        cancelUrl = mergeQueryString({
          url: SITE_URL + '/transform/middlePage',
          mergeObj: {
            callbackFormUrl: encodeURIComponent(cancelUrl),
            bill_no: billno,
          },
        })
      }

      failureUrl = pendingUrl = callbackUrl
    }

    // token场景处理
    if (tokenId) {
      callbackUrl = mergeQueryString({
        url: `${SITE_URL}/ltspc/pay/result/unifiedCb`,
        mergeObj: {
          bill_no: billno,
          isFrontToken: 1,
          ...(oldCheckoutType ? { checkout_type: oldCheckoutType } : {}),
        },
      })
      failureUrl = pendingUrl = callbackUrl
    }
    // afterpay-cashapp特殊处理，sandbox时需兜底一个中间页
    if (paymentCode === 'afterpay-cashapp') {
      callbackUrl = mergeQueryString({
        url: SITE_URL + '/checkout/middlePage',
        mergeObj: {
          callbackUrl,
          paymentMethod: 'afterpay-cashapp',
          countryCode: this.channelExtraInfo.countryCode,
          signUpFlag: +this.unifiedPayParams.signUpFlag === 1 ? 1 : 0,
        },
      })
      pendingUrl = callbackUrl
    }

    if (!failureUrl) failureUrl = callbackUrl
    if (!cancelUrl) cancelUrl = callbackUrl

    debuggerLog('useBsPay===handleUrlParams---', {
      callbackUrl,
      failureUrl,
      cancelUrl,
      pendingUrl,
    })

    this.unifiedPayParams = Object.assign({}, this.unifiedPayParams, {
      callbackUrl,
      failureUrl,
      cancelUrl,
      pendingUrl,
    })

    return {
      callbackUrl,
      failureUrl,
      cancelUrl,
      pendingUrl,
      // cashPayRedirectPath,
    }
  }

  /**
   * 卡前置支付参数格式化
   *
   * @private
   * @param {{ extraParams: CheckstandModalSetupExtraParams; encryptConfig: StoreInfo['encryptConfig'] }} { extraParams, encryptConfig }
   * @memberof CheckstandModal
   */
  private formatPrePayParams = ({ extraParams, encryptConfig, paymentInfo }: { extraParams: CheckstandModalSetupExtraParams; encryptConfig: StoreInfo['encryptConfig']; paymentInfo: CheckstandModalSetup['paymentInfo'] }) => {
    const prePayParams: Trade_PayLibs.LtspcCardPayParams & {
      paymentCode: string;
      freezeParamsStr: string;
    } = {
      routeId: extraParams.routeId || '',
      cardBin: extraParams.cardBin || '',
      cardLastFour: extraParams.cardLastFour || '',
      paymentCode: extraParams.paymentCode || '', // 实际路由到的支付方式
      cpfNumber: extraParams.cpfNumber || '',
      cardHolderName: extraParams.cardHolderName || '',
      rememberCard: extraParams.rememberCard || 0,
      cardType: extraParams.cardType || '',
      autoBindCard: typeof extraParams.autoBindCard === 'number' ? extraParams.autoBindCard : undefined,
      challengeWindowSize: 'fullPage',
      freezeParamsStr: extraParams.freezeParamsStr || '',
      sessionId: extraParams.sessionId || '',
      cardNoHash: '',
      cardNoCheck: '',
      cardExpireMonthHash: '',
      cardExpireMonthCheck: '',
      cardExpireYearHash: '',
      cardExpireYearCheck: '',
      cvvHash: '',
      cvvCheck: '',
      publicKeyId: '',
      cardExpireYearShortHash: '',
      // KR
      cardHolderBirthday: extraParams.cardHolderBirthday || '',
      // KR
      cardEnterpriseBusinessNo: extraParams.cardEnterpriseBusinessNo || '',
      // KR
      cardPasswordFirstTwoDigit: extraParams.cardPasswordFirstTwoDigit ? encryptConfig.encryptObj?.encrypt?.(extraParams.cardPasswordFirstTwoDigit) : '',
    }

    if (paymentInfo?.code === 'routepay-card') {
      const sub = extraParams.expireYear?.substring?.(-2, 2) || ''
      prePayParams.cardExpireYearShortHash = encryptConfig.encryptObj?.encrypt?.(sub || '') || ''
    }

    if (extraParams.installments) {
      prePayParams.installments = extraParams.installments
    }


    // 加密参数处理
    const formatEncryptData = encryptParams({
      sourceData: {
        cardNumber: extraParams.cardNumber,
        expireMonth: extraParams.expireMonth,
        expireYear: extraParams.expireYear,
        cvv: extraParams.cvv,
      },
      publicKeyObj: encryptConfig.publicKeyObj,
      encryptObj: encryptConfig.encryptObj,
    })

    Object.assign(prePayParams, formatEncryptData)
    prePayParams.publicKeyId = encryptConfig.publicKeyObj?.pub_id || ''

    return prePayParams
  }

  private getOldCheckoutType = (checkoutType: string) => {
    if (checkoutType === 'buyprime') return 'buyprime'
    if (checkoutType === 'buyprime_again') return 'buyprime'
    if (checkoutType === 'xtra') return 'xtra'
    if (checkoutType === 'xtra_again') return 'xtra'
    if (checkoutType === 'giftcard') return 'giftcard'
    if (checkoutType === 'giftcard_again') return 'giftcard'
    return ''
  }

  /**
   * 格式化统一支付参数
   *
   * @private
   * @param {(Pick<CheckstandModalSetup, 'orderInfo' | 'paymentInfo' | 'extraParams'> & { preRoutingInfo: CheckstandModalData['preRoutingInfo']; encryptConfig: StoreInfo['encryptConfig'] })} { orderInfo, paymentInfo, extraParams, preRoutingInfo, encryptConfig }
   * @memberof CheckstandModal
   */
  private foramtUnifiedPayParams({ orderInfo, paymentInfo, extraParams, preRoutingInfo, encryptConfig, bsPayConfig }: Pick<CheckstandModalSetup, 'orderInfo' | 'paymentInfo' | 'extraParams' | 'bsPayConfig'> & { preRoutingInfo: CheckstandModalData['preRoutingInfo']; encryptConfig: StoreInfo['encryptConfig'] }) {
    debuggerLog('formatUnifiedPayParams')

    // 浏览器信息相关参数
    const deviceParams = getDeviceInfo()
    // 风控相关参数
    const riskParams = this.bsUtils?.getRiskInfo?.() || {}

    const payParams: Trade_PayLibs.UnifiedPayParams = {} as Trade_PayLibs.UnifiedPayParams

    payParams.billno = orderInfo.billno || orderInfo.relation_billno || ''
    payParams.relation_billno = orderInfo.relation_billno || ''
    payParams.applePayInfo = extraParams.applePayInfo || ''
    payParams.email = paymentInfo.pay_email || ''
    payParams.googlePayInfo = extraParams.googlePayInfo || ''
    payParams.paymentCode = preRoutingInfo?.payMethod || paymentInfo.payment_code || ''
    payParams.phone = paymentInfo.pay_phone || ''
    payParams.phoneCountryCode = paymentInfo.pay_phone_country_code || ''
    payParams.localPhone = paymentInfo.pay_local_phone || ''
    payParams.shopperBankCode = paymentInfo.bank_code || ''
    payParams.shopperBankName = paymentInfo.bank_name || ''
    payParams.signUpFlag = paymentInfo.signupFlag === '1' ? 1 : 0
    payParams.signUpId = extraParams.signUpId || ''
    payParams.childBillnoList = orderInfo.order_group?.length > 1 ? orderInfo.order_group.map(v => v.billno || v).join(',') : ''
    payParams.cancelUrl = extraParams.cancelUrl || ''
    payParams.failureUrl = extraParams.failureUrl || ''
    payParams.routeId = preRoutingInfo?.routeId || extraParams?.routeId || ''
    payParams.vipOrderSource = extraParams?.vipOrderSource || 0
    payParams.checkout_type = this.getOldCheckoutType(bsPayConfig?.checkoutType || '')
    // payParams.tokenId = extraParams.tokenId || ''


    // 渠道参数
    payParams.sessionId = paymentInfo.ddcSessionId || extraParams.sessionId || ''

    // 签约支付不选一次支付流程，才会取token信息
    if (paymentInfo.sign_account_info?.id) {
      payParams.signUpId = paymentInfo.sign_account_info?.id
      payParams.signUpFlag = 0
    }

    // 渠道参数
    payParams.channelDeviceFingerId = extraParams.channelDeviceFingerId || '' // pp
    if (extraParams.sessionId) {
      payParams.sessionId = extraParams.sessionId
    }

    debuggerLog('formatUnifiedPayParams c2pFlag >>>>>', extraParams, payParams)

    if (paymentInfo.use_one_time_sign) {
      //@ts-ignore
      delete payParams.signUpFlag
      //@ts-ignore
      delete payParams.signUpId
    }

    // TODO 卡token支付信息
    if (paymentInfo.card_token_info?.id) {
      if (extraParams.cvv) {
        const formatEncryptData = encryptParams({
          sourceData: {
            cardNumber: '',
            expireMonth: '',
            expireYear: '',
            cvv: extraParams.cvv,
          },
          publicKeyObj: encryptConfig.publicKeyObj,
          encryptObj: encryptConfig.encryptObj,
        })
        Object.assign(payParams, formatEncryptData)
      }
      if (extraParams.installments) {
        payParams.installments = extraParams.installments
      }
      payParams.challengeWindowSize = 'fullPage'
      payParams.tokenId = paymentInfo.card_token_info?.id || ''
      payParams.cardBin = extraParams?.cardBin || paymentInfo.card_token_info?.card_bin || ''
      payParams.cardLastFour = paymentInfo.card_token_info?.last_four_no || ''
      payParams.sessionId = paymentInfo.ddcSessionId || extraParams.sessionId || ''
      payParams.channelDeviceFingerId = extraParams.channelDeviceFingerId || '' // pp
      payParams.publicKeyId = encryptConfig.publicKeyObj?.pub_id || ''
      payParams.neurPayId = payParams.billno + '_' + Math.floor(Math.random() * 99999)
      payParams.neurStep = 1
    }

    if (extraParams?.c2pFlag) {
      payParams.c2pFlag = extraParams?.c2pFlag
      payParams.xSrcCxFlowId = extraParams?.xSrcCxFlowId
      payParams.c2pCorrelationId = extraParams?.c2pCorrelationId
      payParams.merchantTransactionId = extraParams?.merchantTransactionId
      payParams.tokenId = ''
    }

    // TODO 新卡或卡前置支付
    if (extraParams.routeId && extraParams.cardNumber) {
      // 加密参数处理
      const formatedPrePayData = this.formatPrePayParams({
        extraParams,
        encryptConfig,
        paymentInfo,
      })
      Object.assign(payParams, formatedPrePayData)
    }

    this.unifiedPayParams = Object.assign({}, this.unifiedPayParams, payParams, deviceParams, riskParams)
  }

  private formatChannelExtraInfo({ orderInfo, paymentInfo, extraParams }: Pick<CheckstandModalSetup, 'orderInfo' | 'paymentInfo' | 'extraParams'>) {
    const channelExtraInfo: ChannelExtraInfo = {} as ChannelExtraInfo

    channelExtraInfo.countryCode = orderInfo.countryCode || orderInfo.country_code || ''
    channelExtraInfo.clientToken = extraParams.clientToken || ''
    channelExtraInfo.currencyCode = orderInfo.currency_code || ''

    channelExtraInfo.countryId = orderInfo.country_id || ''
    channelExtraInfo.orderAmount = orderInfo.totalPrice?.amount || ''
    channelExtraInfo.sub_billnos = orderInfo.order_group?.map(v => v.billno) || []
    channelExtraInfo.card_logo_list = paymentInfo.card_logo_list || []
    channelExtraInfo.useSync = !!paymentInfo.use_sync
    channelExtraInfo.channelSessionInfo = extraParams.channelSessionInfo || {} as ChannelSessionInfo
    channelExtraInfo.sortedPriceInfo = extraParams.sortedPriceInfo || []
    channelExtraInfo.email = extraParams.email || ''
    // channelExtraInfo.noCardRoutepayAction = paymentInfo.noCardRoutepayAction || ''

    if (paymentInfo.is_apple_pay) {
      channelExtraInfo.supportedNetworks = ApplePayManager.getApplePaySupportedNetworks(paymentInfo?.card_logo_list) || []
    }

    this.channelExtraInfo = Object.assign({}, this.channelExtraInfo, channelExtraInfo)
  }

  public static getInstance(): CheckstandModal {
    // if (!this.instance) {
    //   this.instance = new CheckstandModal()
    // }
    return new CheckstandModal()
  }

  private handleNoCardRoutepayPreRouting = async ({ orderInfo, paymentInfo, apolloInfo }: Pick<CheckstandModalSetup, 'orderInfo' | 'paymentInfo' | 'apolloInfo'>) => {
    const preRoutingPaymentMethods = apolloInfo.PRE_ROUTING_PAYMENT_METHODS?.length ? apolloInfo.PRE_ROUTING_PAYMENT_METHODS : PRE_ROUTING_PAYMENT_METHODS
    if (paymentInfo.noCardRoutepayAction === 'preRouting' || preRoutingPaymentMethods?.includes(paymentInfo.payment_code)) {
      const { relation_billno, billno } = orderInfo || {}
      const useSync = !!paymentInfo.use_sync
      let supportedNetworks: string[] = []

      if (paymentInfo.is_apple_pay) {
        supportedNetworks = ApplePayManager.getApplePaySupportedNetworks(paymentInfo?.card_logo_list) || []
      }

      const formData: PreCardRoutingParams = {
        routeCode: paymentInfo.payment_code,
        relationBillno: relation_billno,
        billno: !relation_billno ? billno : '',
      }

      if (supportedNetworks?.length) {
        formData.cardTypeSupportList = supportedNetworks
      }

      const preRoutingData = await NoCardRoutepayPreRouting.payPreCardRoutingApi({ params: formData, useSync })
      const preRoutingInfo = preRoutingData?.info || {}

      let routing_error_scene = ''
      if (+preRoutingData.code !== 0) routing_error_scene = 'bs_pay_pre_routing_res_error'
      if (+preRoutingData.code === 0) {
        if (!preRoutingInfo?.routeId) routing_error_scene = 'bs_pay_pre_routing_no_routeId'
        if (!preRoutingInfo?.payMethod) routing_error_scene = 'bs_pay_pre_routing_no_payMethod'
        if (preRoutingInfo?.payMethod === paymentInfo.payment_code) routing_error_scene = 'bs_pay_pre_routing_payMethod_error'
      }

      MonitorReportBusiness.metric({
        scene: 'bs_pay_pre_routing',
        extraTags: {
          status_code: preRoutingData.code,
          payment_code: paymentInfo.payment_code,
        },
        extraParams: {
          useSync: useSync ? '1' : '0',
          routeId: preRoutingInfo?.routeId,
          relation_billno: relation_billno,
          billno: billno,
          payMethod: preRoutingInfo?.payMethod,
        },
      })

      routing_error_scene && MonitorReportAbnormal.metric({
        scene: 'bs_pay_pre_routing_error',
        extraTags: {
          status_code: preRoutingData.code,
          failure_type: 'api',
          payment_code: paymentInfo.payment_code,
        },
        extraParams: {
          routing_error_scene,
          error_msg: preRoutingData?.tips || preRoutingData?.msg || '',
          useSync: useSync ? '1' : '0',
          routeId: preRoutingInfo?.routeId,
          relation_billno: relation_billno,
          billno: billno,
          payMethod: preRoutingInfo?.payMethod,
        },
      })

      return preRoutingInfo
    }
    return null
  }

  public static async init({
    orderInfo,
    paymentInfo,
    extraParams,
    bsUtils,
    encryptConfig,
    abtInfo,
    apolloInfo,
    bsPayConfig,
  }: CheckstandModalSetup) {
    const instance = this.getInstance()
    debuggerLog('init---CheckstandModal===', instance, abtInfo)

    if (bsUtils) instance.bsUtils = bsUtils
    if (encryptConfig) instance.encryptConfig = encryptConfig

    const preRoutingInfo = await instance.handleNoCardRoutepayPreRouting({ orderInfo, paymentInfo, apolloInfo })

    instance.preRoutingInfo = preRoutingInfo
    instance.orderInfo = orderInfo
    instance.useInline = !!bsPayConfig?.useInline || false
    instance.paymentCode = preRoutingInfo?.payMethod || paymentInfo.payment_code
    instance.foramtUnifiedPayParams({ orderInfo, paymentInfo, extraParams, preRoutingInfo, encryptConfig: instance.encryptConfig, bsPayConfig })
    instance.formatChannelExtraInfo({ orderInfo, paymentInfo, extraParams })
    instance.formatCbUrlParams({ extraParams, apolloInfo, unifiedPayParams: instance.unifiedPayParams, bsPayConfig })
    // instance.handleCbUrlParams({ orderInfo, paymentInfo, extraParams, apolloInfo })

    debuggerLog('init---CheckstandModal====data=', instance.data)

    return instance.data
  }
  /**
   * 下单创单接口和收银台创单接口出参不一致，需要格式化 ----未使用
   *
   * @static
   * @param {*} orderInfo
   * @memberof CheckstandModal
   */
  public static formatOrderInfo = (orderInfo: any) => {
    const cashierAddress = orderInfo?.address || {}
    const formatedOrder = {
      ...orderInfo,
      billno: orderInfo.billno || orderInfo.relation_billno || '',
      relation_billno: orderInfo.relation_billno || '',
      country_code: orderInfo.countryCode || orderInfo.country_code || '',
      currency_code: orderInfo.currency_code || '',
      country_id: orderInfo.country_id || cashierAddress.country_id || '',
    }
    return formatedOrder
  }
}
