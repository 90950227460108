import { BsPay, type BsPayConstructor, type CompatNewPayment } from '../hooks/useNewBsPay'
import { CreateNewWindow } from '../checkstand/helpers/CreateNewWindow'
import { useBsPayEventBus } from '../hooks/useBsPayCommon'
import { type Trade_PayLibs, type Trade_PayToolKit } from '@shein-aidc/types-trade'

export interface PaymentProcessorConstructor extends Trade_PayLibs.BsPayConfig {
  bsUtils?: Trade_PayLibs.OutsideBsUtils
}
export class PaymentProcessor {
  #instance: BsPay

  constructor(params: PaymentProcessorConstructor) {
    BsPay.bsUtils = params.bsUtils
    this.#instance = new BsPay(params)
    CreateNewWindow.init({
      store: this.#instance.store,
    })
  }

  updateBsPayConfig = (info: Partial<Pick<BsPayConstructor, 'useInline' | 'checkoutType' | 'productType'>>) => {
    this.#instance.updateBsPayConfig(info)
  }

  preCreatePayment = (data: {
    selectedPaymentInfo: Trade_PayToolKit.SelectedPaymentInfo;
    orderInfo;
  }) => {
    this.#instance.preCreatePayment(data)
  }

  bindComponentMethods = (info) => {
    this.#instance.bindComponentMethods(info)
  }

  // handleSdkBridgeAction = () => {
  //   this.#instance.handleSdkBridgeAction()
  // }

  createPayment = (data: CompatNewPayment) => {
    this.#instance.createPayment(data)
  }

  selectPayment = (data: Trade_PayToolKit.AssembledToOutsidePaymentInfo) => {
    this.#instance.selectPayment(data)
  }
  /**
   * 更新支付方式信息
   *
   * @param {{
   *     [key: string]: any;
   *     payment_code?: string;
   *     countryCode?: string;
   *     orderAmount?: string;
   *     orderCurrency?: string;
   *     payMethodList?: string[];
   *     channelSession?: string;
   *   }} data
   * @memberof PaymentProcessor
   */
  updatePayment = (data: {
    [key: string]: any;
    payment_code?: string;
    countryCode?: string;
    orderAmount?: string;
    orderCurrency?: string;
    payMethodList?: string[];
    channelSession?: string;
  }) => {
    this.#instance.updatePayment(data)
  }

  resetPayment = () => {
    this.#instance.resetPayment()
  }
  /**
   * 重置支付工具，用于单页应用kepp-alive场景
   *
   * @memberof PaymentProcessor
   */
  terdownProcessor = () => {
    // useBsPayEventBus不含响应式逻辑，可按需调用
    const { teardown } = useBsPayEventBus()
    // 监听事件销毁
    teardown?.()
  }

  getPaymentProcessInfo = ({
    paymentInfo,
    extraParams,
  }: Pick<CompatNewPayment, 'paymentInfo' | 'extraParams'>) => {
    return this.#instance.getPaymentProcessInfo({ paymentInfo, extraParams })
  }
}
