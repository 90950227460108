import { type PayPalCheckout, type PayPalCheckoutCreatePaymentOptions } from 'braintree-web'
import type { PayPalCheckoutLoadPayPalSDKOptions } from 'braintree-web/paypal-checkout'
import type { ButtonStyle, AuthorizationResponse, CancellationData } from 'paypal-checkout-components'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'

import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { loadChannelSdk } from '../../channel/initSdk'
import { InitPaypalSdk } from '../helpers/InitPaypalSdk'
import { ppgaInstanceCache } from '../caches'
import { bsPayEventBus } from '../../hooks/useBsPayCommon'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { PAYMENT_ACTION_TYPE } from '../../../types'

export type {
  ButtonStyle,
  AuthorizationResponse,
}

export interface LoadPaypalGaButtonOptions {
  fundingSource: string;
  paypalStyle: ButtonStyle;
  sdkConfig: PayPalCheckoutLoadPayPalSDKOptions;
  createPaymentOptions: PayPalCheckoutCreatePaymentOptions,
  onClick?: () => void;
  onCancel?: (data?: CancellationData) => void
  onError?: (error?: string) => void
  onApproved: (TokenizePayload: AuthorizationResponse) => AuthorizationResponse | Promise<AuthorizationResponse>
}

export enum PayPalButtonStatus {
  INIT_PAYPAL_SDK_SUCCESS = 'INIT_PAYPAL_SDK_SUCCESS',
  INIT_PAYPAL_SDK_ERROR = 'INIT_PAYPAL_SDK_ERROR',
  LOAD_PAYPAL_SDK_SUCCESS = 'LOAD_PAYPAL_SDK_SUCCESS',
  LOAD_PAYPAL_SDK_ERROR = 'LOAD_PAYPAL_SDK_ERROR',
  LOAD_PAYPAL_MESSAGE_SUCCESS = 'LOAD_PAYPAL_MESSAGE_SUCCESS',
  LOAD_PAYPAL_MESSAGE_ERROR = 'LOAD_PAYPAL_MESSAGE_ERROR',
  RENDER_PAYPAL_BUTTON_SUCCESS = 'RENDER_PAYPAL_BUTTON_SUCCESS',
  RENDER_PAYPAL_BUTTON_ERROR = 'RENDER_PAYPAL_BUTTON_ERROR',
}

export class PaypalGaManager extends BasicPayManager implements AbstractMethods {

  private static instance: PaypalGaManager

  private static paypalGaInstance: PayPalCheckout

  private static onClick: () => void

  private static onCancel: LoadPaypalGaButtonOptions['onCancel']

  private static onError: LoadPaypalGaButtonOptions['onError']

  private static onComplete: (status: PayPalButtonStatus, error?) => void

  private static onApproved: LoadPaypalGaButtonOptions['onApproved']

  public static PAYMENT_CODE = 'PayPal-GApaypal'

  public static isRunningInitLoadPaypalBtn = false

  public static paypalRenderBtns: string[] = []

  private static channelDeviceFingerId: string

  private resolve: (res: any) => void = () => {}

  private reject: (error: any) => void = () => {}

  public paymentCode: string = PaypalGaManager.PAYMENT_CODE

  constructor(params: BasicPayManagerConstructor) {
    super(params)
    PaypalGaManager.instance = this
  }

  private static teardownCache = async (caches = ppgaInstanceCache, cacheKey = '') => {
    if (cacheKey) {
      const singleClientInstance = caches.get(cacheKey)
      singleClientInstance?.teardown?.(() => {})
      caches?.delete?.(cacheKey)
    } else {
      for (const key in caches) {
        if (caches.has(key)) {
          const clientInstance = caches.get(key)
          clientInstance?.teardown?.(() => {})
          caches.delete(key)
        }
      }
    }
  }

  private static initPaypalGaInstnace = async ({ clientToken, profileId }) => {
    const cacheKey = clientToken + profileId
    if (ppgaInstanceCache.has(cacheKey) && ppgaInstanceCache.get(cacheKey)) {
      this.paypalGaInstance = ppgaInstanceCache.get(cacheKey) as PayPalCheckout
      return this.paypalGaInstance
    }

    this.teardownCache(ppgaInstanceCache)

    if(!clientToken) {
      MonitorReportAbnormal.metric({
        scene: 'paypal_client_token_again_error',
        extraTags: {
          failure_type: 'api',
          payment_code: this.PAYMENT_CODE,
        },
        extraParams: {
          description: 'Paypal 缺少clientToken',
          client_url: '/api/checkout/channelSession/create',
          payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
        },
      })
    }

    try {
      const instance = await InitPaypalSdk.initPaypalGaSdk({ clientToken })
      ppgaInstanceCache.set(cacheKey, instance)
      this.paypalGaInstance = instance
      // this.onComplete?.(PayPalButtonStatus.INIT_PAYPAL_SDK_SUCCESS)
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.INIT_PAYPAL_SDK_SUCCESS })

      return instance
    } catch (error) {
      // this.onComplete?.(PayPalButtonStatus.INIT_PAYPAL_SDK_ERROR, error)
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.INIT_PAYPAL_SDK_ERROR })

      MonitorReportAbnormal.metric({
        scene: 'paypal_init_sdk_error',
        extraTags: {
          failure_type: 'sdk',
          payment_code: this.PAYMENT_CODE,
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'Paypal获取 session script 失败',
          error_msg: (error as any)?.message || '',
          error_stack: JSON.stringify(error || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
        },
      })
    }
  }

  public static getInstance = () => {
    return PaypalGaManager.instance
  }

  public static getPaypalGaInstance = () => {
    return this.paypalGaInstance
  }

  public static initPPGASdk = async () => {
    await loadChannelSdk.initPPGASdk()
  }

  public static initPPGAPay = async ({
    clientToken,
    profileId,
  }) => {
    await this.initPaypalGaInstnace({ clientToken, profileId })
    this.getPaypalDeviceInfo(clientToken).then(deviceData => {
      this.channelDeviceFingerId = deviceData || ''
    })
  }

  public static getPaypalDeviceInfo = async (clientToken) => {
    if (!clientToken) return ''
    try {
      return await InitPaypalSdk.getPaypalDeviceInfo({ clientToken })
    } catch(e) {
      return ''
    }
  }

  public static registerPPGAPay = async ({
    onClick,
    onApproved,
    onCancel,
    onError,
    onComplete,
    ele,
  }) => {
    this.onClick = onClick
    this.onApproved = onApproved
    this.onCancel = onCancel
    this.onError = onError
    this.onComplete = onComplete
    if(!this.paypalRenderBtns.includes(ele)) {
      this.paypalRenderBtns.push(ele)
    }
    return Promise.resolve()
  }

  public static loadPaypalGaBtn = async ({
    sdkConfig,
    ...restOptions
  }: Omit<LoadPaypalGaButtonOptions, 'onApproved'>) => {
    // 防止多个paypal按钮同时执行 loadPaypalGaBtn
    if(this.isRunningInitLoadPaypalBtn){
      return
    }
    this.isRunningInitLoadPaypalBtn = true
    return this.paypalGaInstance && this.paypalGaInstance.loadPayPalSDK(sdkConfig).then(() => {
      window?.sa && window?.sa('send', {
        activity_name: 'expose_sdkjs_result',
        activity_param: {
          payment_method: 'PayPal-GApaypal',
          return_status: 'sdkjsapi_return_success',
        },
      })
      return this.renderPaypalBtn(restOptions)
    }).catch((error) => {
      this.isRunningInitLoadPaypalBtn = false
      window?.sa && window?.sa('send', {
        activity_name: 'expose_sdkjs_result',
        activity_param: {
          payment_method: 'PayPal-GApaypal',
          return_status: 'sdkjsapi_return_failure',
        },
      })
      MonitorReportAbnormal.metric({
        scene: 'paypal_init_sdk_error',
        extraTags: {
          failure_type: 'sdk',
          payment_code: this.PAYMENT_CODE,
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'Paypal获取 session script 失败',
          error_msg: (error as any)?.message || '',
          error_stack: JSON.stringify(error || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
        },
      })
      return Promise.reject(error)
    })
  }

  public static loadPaypalMessage = async ({
    sdkConfig,
  }: Pick<LoadPaypalGaButtonOptions, 'sdkConfig'>) => {
    return this.paypalGaInstance && this.paypalGaInstance.loadPayPalSDK(sdkConfig)
    .then(() => {
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.LOAD_PAYPAL_MESSAGE_SUCCESS })
    }).catch(() => {
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.LOAD_PAYPAL_MESSAGE_ERROR })
    })
  }

  public static async renderPaypalBtn({
    fundingSource,
    createPaymentOptions,
    paypalStyle,
  }: Omit<LoadPaypalGaButtonOptions, 'sdkConfig' | 'onApproved'>) {
    if(this.paypalRenderBtns.length === 0) {
      return
    }
    const renderButtons = this.paypalRenderBtns.filter((ele) => !!document.querySelector(ele))
    const paypalGaInstance = this.paypalGaInstance
    const paypaylButtonsRenderers = renderButtons.map((ele) => {
      return window.paypal.Buttons({
        fundingSource,
        style: paypalStyle || {},
        createOrder: function() {
          return paypalGaInstance && paypalGaInstance
            .createPayment(createPaymentOptions)
            .then((data) => {
              window?.sa && window?.sa('send', {
                activity_name: 'expose_popup_paypal',
                activity_param: {
                  payment_method: 'PayPal-GApaypal',
                },
              })
              if (data) {
                return data
              } else {
                throw new Error('Payment creation returned void')
              }
            })
        },
        onClick: this.onClick,
        onApprove: (data) => {
          return paypalGaInstance
            .tokenizePayment(data)
            .then<AuthorizationResponse, any>((payload) => {
              let nonceReturnStatus = 'nonceapi_return_success'
              if (!payload?.nonce) {
                nonceReturnStatus = 'nonceapi_return_failure'

                MonitorReportAbnormal.metric({
                  scene: 'paypal_get_nonce_error',
                  extraTags: {
                    failure_type: 'sdk',
                    payment_code: this.PAYMENT_CODE,
                  },
                  extraParams: {
                    client_url: '/third/sdk/error',
                    description: 'Paypal 获取SDK对应nonce失败, ' + nonceReturnStatus,
                    payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
                  },
                })
              }
              window?.sa && window?.sa('send', {
                activity_name: 'expose_nonce_result',
                activity_param: {
                  payment_method: 'PayPal-GApaypal',
                  return_status: nonceReturnStatus,
                },
              })

              return this.onApproved?.(payload)
            })
            .catch((err) => {
              window?.sa && window?.sa('send', {
                activity_name: 'expose_nonce_result',
                activity_param: {
                  payment_method: 'PayPal-GApaypal',
                  return_status: 'nonceapi_not_response',
                },
              })
              MonitorReportAbnormal.metric({
                scene: 'paypal_get_nonce_error_catch',
                extraTags: {
                  failure_type: 'sdk',
                  payment_code: this.PAYMENT_CODE,
                },
                extraParams: {
                  client_url: '/third/sdk/error',
                  description: 'Paypal 获取SDK对应nonce失败',
                  error_stack: JSON.stringify(err || {}),
                  payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
                },
              })
            })
        },
        onCancel: (data) => {
          window?.sa && window?.sa('send', {
            activity_name: 'click_paypalpopup_close',
            activity_param: {
              payment_method: 'PayPal-GApaypal',
            },
          })
          const billno = PaypalGaManager.instance?.unifiedPayParams?.billno || ''

          MonitorReportAbnormal.metric({
            scene: 'paypal_render_on_cancel',
            extraTags: {
              failure_type: 'api',
              payment_code: this.PAYMENT_CODE,
            },
            extraParams: {
              billno: billno,
              client_url: '/third/sdk/error',
              description: `Paypal 支付取消: ${billno}`,
              payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
            },
          })
          return this.onCancel?.(data)
        },
        onError: (error) => {
          window?.sa && window?.sa('send', {
            activity_name: 'expose_paypalpopup_error',
            activity_param: {
              payment_method: 'PayPal-GApaypal',
            },
          })

          const billno = PaypalGaManager.instance?.unifiedPayParams?.billno || ''

          MonitorReportAbnormal.metric({
            scene: 'paypal_render_on_error',
            extraTags: {
              failure_type: 'api',
              payment_code: this.PAYMENT_CODE,
            },
            extraParams: {
              billno: billno,
              client_url: '/third/sdk/error',
              description: `Paypal 支付信息错误: billno:${billno} `,
              payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
            },
          })
          return this.onError?.(error)
        },
      }).render(ele)
    })
    return Promise.all(paypaylButtonsRenderers).then(() => {
        // render paypal btn success
      // this.onComplete?.(PayPalButtonStatus.RENDER_PAYPAL_BUTTON_SUCCESS)
      this.isRunningInitLoadPaypalBtn = false
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.RENDER_PAYPAL_BUTTON_SUCCESS })
    }).catch((err) => {
      // render paypal btn failed
      // this.onComplete?.(PayPalButtonStatus.RENDER_PAYPAL_BUTTON_ERROR, error)
      this.isRunningInitLoadPaypalBtn = false
      bsPayEventBus.paypalLoadStatus.emit({ status: PayPalButtonStatus.RENDER_PAYPAL_BUTTON_ERROR })

      MonitorReportAbnormal.metric({
        scene: 'paypal_render_btn_catch_error',
        extraTags: {
          failure_type: 'api',
          payment_code: this.PAYMENT_CODE,
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: `Paypal 按钮渲染失败: ${String(err?.message || err?.stack || '').substring(0, 100)}`,
          payment_action_type: PAYMENT_ACTION_TYPE.PAYPAL_SDK,
        },
      })
    })
  }

  public async createPayment (): Promise<any> {
    return new Promise((resolve, reject) => {
      this.paymentLoadingAction?.({ show: true })
      this.resolve = (res) => {
        this.paymentLoadingAction?.({ show: false })
        if (res.status === 'success' || res.status === 'pending') {
          this.onSuccess?.(res.result as Trade_PayLibs.UnifiedPayRes, {
            mergeQuery: {
              is_vaulting: this.unifiedPayParams.signUpFlag || this.unifiedPayParams.signUpId ? 1 : 0,
            },
          })
        } else if (res.status === 'error') {
          this.onError?.(res.result as Trade_PayLibs.UnifiedPayRes)
        } else if (res.status === 'catchError') {
          this.onFail?.(res.result as any)
        }
        resolve(res)
      }

      this.reject = (error) => {
        this.paymentLoadingAction?.({ show: false })
        this.onError?.(error)
        reject(error)
      }

      if (!this.unifiedPayParams?.channelDeviceFingerId) {
        this.updateUnifiedPayParams({ channelDeviceFingerId: PaypalGaManager.channelDeviceFingerId || '' })
      }

      // paypal签约走这里
      this.handleUnifiedPay().then(this.resolve).catch(this.reject)
    })
  }
}
