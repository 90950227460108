import { asyncLoadFile } from '@shein/common-function'

class InitChannelSdk {
  #SDK_MAP = {
    'pp-client': 'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    'pp-checkout': 'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
    'pp-data-collector': 'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
    'pp-venmo': 'https://js.braintreegateway.com/web/3.85.2/js/venmo.min.js',
    'pp-three-d-secure': 'https://js.braintreegateway.com/web/3.85.2/js/three-d-secure.min.js',
    'pp-hosted-fields': 'https://js.braintreegateway.com/web/3.85.2/js/hosted-fields.min.js',
    'klarna': 'https://x.klarnacdn.net/kp/lib/v1/api.js',
    'gp': 'https://pay.google.com/gp/p/js/pay.js',
    'afterpay-cashapp': 'https://portal.afterpay.com/afterpay.js',
    'afterpay-card': 'https://portal.afterpay.com/afterpay.js',
    'apple-pay-button': 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js',
    'afterpay-clearpay': 'https://portal.clearpay.co.uk/afterpay.js',
    'paidy-paylater': 'https://apps.paidy.com/',
    'adyen-checkout': 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.js',
    'sandbox-afterpay-cashapp': 'https://portal.sandbox.afterpay.com/afterpay.js',
    'sandbox-afterpay-card': 'https://portal.sandbox.afterpay.com/afterpay.js',
    'sandbox-afterpay-clearpay': 'https://portal.sandbox.clearpay.co.uk/afterpay.js',
  }

  #STYLE_MAP = {
    'adyen-style': 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.css',
  }

  #SANDBOX_SDK_MAP = {
    'afterpay-cashapp': 'https://portal.sandbox.afterpay.com/afterpay.js',
    'afterpay-card': 'https://portal.sandbox.afterpay.com/afterpay.js',
    'afterpay-clearpay': 'https://portal.sandbox.clearpay.co.uk/afterpay.js',
  }

  #PromiseStatus = {}

  #SdkLoadPromiseMap = {}

  #PPGA_SDKS = ['pp-client', 'pp-data-collector', 'pp-checkout']

  #VENMO_SDKS = ['pp-client', 'pp-data-collector', 'pp-venmo']

  #KLARNA_SDKS = ['klarna']

  #GP_SDKS = ['gp']

  #AFTERPAY_CASHAPP_SDKS = ['afterpay-cashapp']

  #SENDBOX_AFTERPAY_CASHAPP_SDKS = ['sandbox-afterpay-cashapp']

  #AP_PAY_BUTTON_SDKS = ['apple-pay-button']

  #PAIDY_SDKS = ['paidy-paylater']

  #ADYEN_SDKS = [{ sdkName: 'adyen-checkout', type: 'javascript' }, { sdkName: 'adyen-style', type: 'style' }]

  #PP_CARD_SDKS = ['pp-client', 'pp-three-d-secure', 'pp-hosted-fields']

  #createAsyncLoadFilePromise({ sdkName, type }: { sdkName: string; type: 'javascript' | 'style' }) {
    const sdk = this.#SDK_MAP[sdkName] || this.#STYLE_MAP[sdkName]
    const status = this.#PromiseStatus[sdkName]
    if (status === 'fulfilled') return Promise.resolve(true)
    else if (this.#SdkLoadPromiseMap[sdkName] && status === 'pending') return this.#SdkLoadPromiseMap[sdkName]
    this.#PromiseStatus[sdkName] = 'pending'

    const createLoadFileConfig = ({ sdkName, type, sdk }: { sdkName: string; type: 'javascript' | 'style'; sdk: string }): {
      label: string;
      attrs: {
        src?: string;
        href?: string;
        id: string;
        rel?: string;
        type?: string;
        style?: string;
        async?: string;
      };
    } => {
      if (type === 'style') {
        return {
          label: 'link',
          attrs: {
            rel: 'stylesheet',
            href: sdk,
            id: `channel-${sdkName}`,
            async: 'async',
          },
        }
      }
      return {
        label: 'script',
        attrs: {
          src: sdk,
          id: `channel-${sdkName}`,
          async: 'async',
        },
      }
    }

    return this.#SdkLoadPromiseMap[sdkName] = asyncLoadFile(createLoadFileConfig({ sdkName, type, sdk }))
  }

  #checkSDKLoaded(sdkNames) {
    return sdkNames.every(sdkName => this.#PromiseStatus[sdkName] === 'fulfilled')
  }

  #createLoadSDKPromise(sdks) {
    return Promise.allSettled(sdks.map(sdkInfo => this.#createAsyncLoadFilePromise({
      sdkName: sdkInfo.sdkName || sdkInfo,
      type: sdkInfo?.type || 'script',
    })))
  }

  async initSdk(sdks) {
    if (this.#checkSDKLoaded(sdks)) {
      return { datas: true }
    }

    const result = await this.#createLoadSDKPromise(sdks)
    result.forEach((res, index) => {
      this.#PromiseStatus[sdks[index]] = res.status
    })
    return { datas: true }
  }


  async initPPGASdk() {
    return await this.initSdk(this.#PPGA_SDKS)
  }

  async initVenmoSdk() {
    return await this.initSdk(this.#VENMO_SDKS)
  }

  async initKlarnaSdk() {
    return await this.initSdk(this.#KLARNA_SDKS)
  }

  async initGPSdk() {
    return await this.initSdk(this.#GP_SDKS)
  }

  async initAfterPayCashAppSdk(options?: { isSandBox?: boolean }) {
    return await this.initSdk(options?.isSandBox ? this.#SENDBOX_AFTERPAY_CASHAPP_SDKS : this.#AFTERPAY_CASHAPP_SDKS)
  }

  async initAfterPaySdk(PayCod: string) {
    return await this.initSdk([PayCod])
  }

  async initApplePayBtnSdk() {
    return await this.initSdk(this.#AP_PAY_BUTTON_SDKS)
  }

  async initPaidySdk() {
    return await this.initSdk(this.#PAIDY_SDKS)
  }

  async initAdyenSdk() {
    return await this.initSdk(this.#ADYEN_SDKS)
  }

  async initPPCardSdk() {
    return await this.initSdk(this.#PP_CARD_SDKS)
  }
}

export const loadChannelSdk = new InitChannelSdk()
