import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { loadChannelSdk } from '../../channel/initSdk'
import { debuggerLog } from '../../utils'
import { type Trade_PayLibs } from '@shein-aidc/types-trade'
import { mergeQueryString, parseQueryString, extractQueryString } from '@shein/common-function'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { useEventBus } from '../../hooks/useEventBus'
import { CHECKOUT_TYPE, type ChannelSessionInfo } from '../../../types'

interface UseKlarnaPayments {
  client_token: string;
  payment_method_category?: string;
}

interface DataUpdateType {
  shipping_address?: Record<string, any>;
  billing_address?: Record<string, any>;
}

export class KlarnaInlinePayment {

  private isFormLoaded: boolean = false

  private static klarnaSDKIsLoad: boolean = false

  private static instances: Map<string, KlarnaInlinePayment> = new Map()

  private static klarnaSessionInfo: UseKlarnaPayments = {} as UseKlarnaPayments

  private widgetContainer: string = ''

  private event = useEventBus<{ scene: string }>(Symbol('klarna_inline'))

  constructor(data: { key: string }) {
    this.widgetContainer = data.key
    this.event.off(this.eventHandler)
    this.event.on(this.eventHandler)
  }

  private eventHandler = (data: { scene: string }) => {
    if (data.scene === 'initialize') {
      this.renderKlarnaWidgetFromContainer()
    }
  }

  private renderKlarnaWidgetFromContainer = () => {
    const klarnaPayments = KlarnaInlinePayment.getGlobalKlarnaPayments()
    debuggerLog('KlarnaInlinePayment===renderKlarnaWidgetFromContainer', klarnaPayments, this.widgetContainer, KlarnaInlinePayment.klarnaSessionInfo)
    if (!(window as any)?.Klarna || !klarnaPayments || !this.widgetContainer) {
      throw new Error('Klarna is not initialized.')
    }

    const { payment_method_category = '' } = KlarnaInlinePayment.klarnaSessionInfo

    try {
      const hasDom = document.querySelector?.(`.${this.widgetContainer}`)
      if (!hasDom) {
        KlarnaInlinePayment.removeInstance(this.widgetContainer)
        return
      }

      klarnaPayments.load({
        container: `.${this.widgetContainer}`,
        payment_method_category: payment_method_category ||'',
      }, (res: any) => {
        debuggerLog('KlarnaInlinePayment===load', res)
        if (res.show_form) {
          this.isFormLoaded = true
        } else {
          MonitorReportAbnormal.metric({
            scene: 'klarna_inline_load_show_form_error',
            extraTags: {
              failure_type: 'sdk',
              payment_code: KlarnaInlineManager.PAYMENT_CODE,
            },
            extraParams: {
              error_stack: JSON.stringify(res || {}),
              client_url: '/inline/klarna/load',
            },
          })
          throw new Error(res.error)
        }
      })
    } catch (error) {
      throw new Error(`Failed to load Klarna form: ${error}`)
    }
  }

  static get checkFormLoaded () {
    for (const instance of this.instances.values()) {
      if (instance.isFormLoaded) return true
    }
    return false
  }

  static get clientToken () {
    return this.klarnaSessionInfo.client_token
  }

  public static setInstance(key: string): void {
    if (!KlarnaInlinePayment.instances.has(key)) {
      KlarnaInlinePayment.instances.set(key, new KlarnaInlinePayment({ key }))
      if (this.klarnaSessionInfo.client_token && this.klarnaSessionInfo.payment_method_category && this.getGlobalKlarnaPayments()) {
        if (typeof document === 'undefined') return
        KlarnaInlinePayment.getInstance(key).renderKlarnaWidgetFromContainer()
      }
    }
  }

  public static getInstance(key: string): KlarnaInlinePayment {
    if (!KlarnaInlinePayment.instances.has(key)) {
      KlarnaInlinePayment.instances.set(key, new KlarnaInlinePayment({ key }))
    }
    return KlarnaInlinePayment.instances.get(key) as KlarnaInlinePayment
  }

  public static removeInstance(key: string): void {
    const instance = KlarnaInlinePayment.instances.get(key)
    if (!instance) return
    instance.event.off(instance.eventHandler)
    KlarnaInlinePayment.instances.delete(key)
    document.querySelector(`.${key}`)?.remove?.()
  }

  public static resetInstances(): void {
    for (const instance of this.instances.values()) {
      this.removeInstance(instance.widgetContainer)
    }
  }

  private static initializeKlarna = (options: UseKlarnaPayments) => {
    return new Promise((resolve, reject) => {
      try {
        (window as any)?.Klarna.Payments.init({
          client_token: options?.client_token || '',
        })
        this.klarnaSessionInfo = options
        for (const instance of this.instances.values()) {
          instance.event.emit({ scene: 'initialize' })
        }
        resolve(null)
      } catch (error) {
        reject(error)
        throw new Error(`Failed to initialize Klarna: ${error}`)
      }
    })
  }

  public static getGlobalKlarnaPayments = () => {
    if (typeof window === 'undefined') return false
    return (window as any)?.Klarna?.Payments
  }

  public static initializeAndLoadKlarna = async (options: UseKlarnaPayments) => {
    if (typeof window === 'undefined') return
    try {
      (window as any).klarnaAsyncCallback = async () => {
        this.klarnaSDKIsLoad = true
        debuggerLog('klarnaAsyncCallback', options)
        this.initializeKlarna(options)
      }

      if (this.klarnaSDKIsLoad) {
        this.initializeKlarna(options)
      }

      await loadChannelSdk.initKlarnaSdk()
    } catch (error) {
      throw new Error(`Failed to load Klarna: ${error}`)
    }
  }
}


export interface KlarnaInlineManagerConstructor extends BasicPayManagerConstructor { }

export class KlarnaInlineManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = 'klarna-sliceitdirect'

  public paymentCode: string = KlarnaInlineManager.PAYMENT_CODE

  constructor(params: KlarnaInlineManagerConstructor) {
    super(params)
  }

  public static initializeAndLoadKlarna = KlarnaInlinePayment.initializeAndLoadKlarna

  public static inlineSessionCaches: Map<string, ChannelSessionInfo> = new Map()

  private static payAgainInlineError: boolean = false

  static clearInlineSessionCaches = () => {
    KlarnaInlineManager.inlineSessionCaches.clear()
  }

  static needRefreshInlineSession ({
    paymentCode,
  }) {
    return paymentCode === this.PAYMENT_CODE && this.payAgainInlineError
  }

  authorizePayment = (result: Trade_PayLibs.UnifiedPayRes) => {
    const klarnaPayments = KlarnaInlinePayment.getGlobalKlarnaPayments()
    if (!KlarnaInlinePayment.checkFormLoaded || !klarnaPayments) {
      KlarnaInlineManager.payAgainInlineError = true
      this.onError?.(result)
      MonitorReportAbnormal.metric({
        scene: 'klarna_inline_auth_form_not_loaded',
        extraTags: {
          failure_type: 'sdk',
          payment_code: this.paymentCode,
        },
        extraParams: {
          checkFormLoaded: KlarnaInlinePayment.checkFormLoaded ? '1' : '0',
          loadKlarnaPayments: klarnaPayments ? '1' : '0',
          client_url: '/inline/klarna/authorize',
        },
      })
      return
    }

    const { paramList, neurData } = result.info || {}
    const { callbackUrl } = this.unifiedPayParams
    const { channelSessionInfo } = this.payData.channelExtraInfo || {}

    const paymentsData = {} as DataUpdateType
    if (paramList?.shipping_address) {
      paymentsData.shipping_address = paramList.shipping_address || {}
    }
    if (paramList?.billing_address) {
      paymentsData.billing_address = paramList.billing_address
    }

    if (channelSessionInfo?.clientToken !== KlarnaInlinePayment.clientToken) {
      MonitorReportAbnormal.metric({
        scene: 'klarna_inline_auth_client_token_compare_error',
        extraTags: {
          failure_type: 'web',
          payment_code: this.paymentCode,
        },
        extraParams: {
          error_stack: JSON.stringify(channelSessionInfo || {}),
          client_url: '/inline/klarna/authorize',
        },
      })
    }

    klarnaPayments.authorize({
      payment_method_category: channelSessionInfo?.paymentMethodCategory,
    },
    paymentsData,
    async (res: any) => {
      debuggerLog('authorizePayment >>>>>', res)
      if (!res?.show_form) {
        MonitorReportAbnormal.metric({
          scene: 'klarna_inline_auth_show_form_error',
          extraTags: {
            failure_type: 'sdk',
            payment_code: this.paymentCode,
          },
          extraParams: {
            error_stack: JSON.stringify(res || {}),
            client_url: '/inline/klarna/authorize',
          },
        })
        KlarnaInlineManager.payAgainInlineError = true
        return this.onError?.(res.error)
      }
      if (res.approved) {
        if (res.authorization_token) {
          let neurStep = neurData?.neurStep
          if (neurData?.neurStep && neurData?.neurPayId) {
            neurStep = neurStep + 1
          }
          const locationUrl = mergeQueryString({
            url: callbackUrl,
            mergeObj: {
              paymentCode: KlarnaInlineManager.PAYMENT_CODE,
              gatewayPayNo: paramList.gateway_pay_no,
              token: res.authorization_token,
              neurStep,
              neurPayId: neurData?.neurPayId,
            },
          })

          const params = parseQueryString(extractQueryString(locationUrl)) as unknown as Trade_PayLibs.UnifiedPayCbParams
          if (params.combineGatewayPayNo && Array.isArray(params.combineGatewayPayNo)) {
            params.combineGatewayPayNo = params.combineGatewayPayNo?.[0]
          }
          const cbRes = await this.handleUnifiedCallback(params)
          if (cbRes.status === 'success') {
            this.onSuccess?.(cbRes.result as Trade_PayLibs.UnifiedPayRes)
          } else {
            KlarnaInlineManager.payAgainInlineError = true
            this.onError?.(cbRes.result as Trade_PayLibs.UnifiedPayRes)
          }
        } else {
          MonitorReportAbnormal.metric({
            scene: 'klarna_inline_auth_no_token',
            extraTags: {
              failure_type: 'sdk',
              payment_code: this.paymentCode,
            },
            extraParams: {
              billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
              error_stack: JSON.stringify(res || {}),
              client_url: '/inline/klarna/authorize',
            },
          })
          KlarnaInlineManager.payAgainInlineError = true
          this.onError?.(res.error)
        }
      } else {
        MonitorReportAbnormal.metric({
          scene: 'klarna_inline_auth_not_approved',
          extraTags: {
            failure_type: 'sdk',
            payment_code: this.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
            error_stack: JSON.stringify(res || {}),
            client_url: '/inline/klarna/authorize',
          },
        })
        this.onCancel?.()
      }
    })
  }

  async createPayment () {
    KlarnaInlineManager.payAgainInlineError = false
    this.paymentLoadingAction?.({ show: true })
    debuggerLog('KlarnaInlineManager createPayment', KlarnaInlinePayment.checkFormLoaded, this.payData.channelExtraInfo?.channelSessionInfo?.sessionId)
    if (KlarnaInlinePayment.checkFormLoaded) {
      const sessionId = this.payData.channelExtraInfo?.channelSessionInfo?.sessionId || ''
      this.updateUnifiedPayParams({ sessionId })
      // if (sessionId) {
      //   const sessionCacheKey = sessionId + this.payData?.unifiedPayParams?.relation_billno || ''
      //   const hasCache = KlarnaInlineManager.inlineSessionCaches.has(sessionCacheKey)
      //   if (!hasCache) {
      //     KlarnaInlineManager.inlineSessionCaches.set(sessionCacheKey, (this.payData.channelExtraInfo?.channelSessionInfo || {}) as ChannelSessionInfo)
      //   }
      // }
    }
    const { status, result } = await this.handleUnifiedPay()
    this.paymentLoadingAction?.({ show: false })
    if (status === 'success') this.onSuccess?.(result as Trade_PayLibs.UnifiedPayRes)
    if (status === 'error') this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
    if (status === 'catchError') this.onFail?.(result as any)
    if (status === 'cancel') this.onCancel?.()
    if (status === 'continue') {
      if (result?.info?.action === 'redirect') {
        this.normalHandleRedirectAction(result as Trade_PayLibs.UnifiedPayRes)
      } else if (result?.info?.action === 'render') {
        this.authorizePayment(result as Trade_PayLibs.UnifiedPayRes)
      } else {
        this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
      }
    }
    if ([
      CHECKOUT_TYPE.BUYPRIME_AGAIN,
      CHECKOUT_TYPE.CASHIER_AGAIN,
      CHECKOUT_TYPE.NORMAL_AGAIN,
      CHECKOUT_TYPE.GIFTCARD_AGAIN,
    ].includes(this.payCheckoutType)) {
      if (status !== 'success') {
        if (status !== 'continue' || (status === 'continue' && result?.info?.action !== 'redirect')) {
          KlarnaInlineManager.payAgainInlineError = true
        }
      }
    }
    return { status, result }
  }
}
