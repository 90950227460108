// 支付单例
// import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
// import { debuggerLog } from '../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

import { PaidyPayManager } from './tools/PaidyPayManager'
import { PaypalVenmoManager } from './tools/PaypalVenmoManager'
import { PaypalGaManager } from './tools/PaypalGaManager'
import { AfterPayCashAppManager } from './tools/AfterPayCashAppManager'
import { LtspcPayManager } from './tools/LtspcPayManager'
import { NormalPayManager } from './tools/NormalPayManager'
import { SelfBuiltPagePayManager } from './tools/SelfBuiltPagePayManager'
import { GooglePayManager } from './tools/GooglePayManager'
import { ApplePayManager } from './tools/ApplePayManager'
import { FrontTokenPayManager } from './tools/FrontTokenPayManager'
import { FrontPayManager } from './tools/FrontPayManager'
import { KlarnaInlineManager } from './tools/KlarnaInlineManager'
import { CodPayManager } from './tools/CodPayManager'
import { BlikPayDialogManager } from './tools/BlikPayDialogManager'
import { type BasicPayManagerConstructor, type PayManagerActionMethods } from './BasicPayManager'
import { CreateNewWindow } from './helpers/CreateNewWindow'
import { debuggerLog } from '../utils'


export interface CheckstandManagerConstructor extends BasicPayManagerConstructor, PayManagerActionMethods {
  bsUtils: Trade_PayLibs.OutsideBsUtils;
  bsPayConfig: Trade_PayLibs.BsPayConfig;
  encryptConfig?: {
    encryptValid?: boolean;
  };
}

type CheckstandManagerInstance =
  PaidyPayManager |
  PaypalVenmoManager |
  PaypalGaManager |
  AfterPayCashAppManager |
  KlarnaInlineManager |
  CodPayManager |
  NormalPayManager |
  SelfBuiltPagePayManager |
  GooglePayManager |
  ApplePayManager |
  FrontTokenPayManager |
  FrontPayManager |
  LtspcPayManager |
  BlikPayDialogManager

export class CheckstandManager {
  private static instance: CheckstandManager

  private managerConfig: CheckstandManagerConstructor = {} as CheckstandManagerConstructor

  private bsUtils: Trade_PayLibs.OutsideBsUtils = {} as Trade_PayLibs.OutsideBsUtils

  private bsConfig: Trade_PayLibs.BsPayConfig = {} as Trade_PayLibs.BsPayConfig

  private managerInstance: CheckstandManagerInstance = {} as CheckstandManagerInstance

  constructor() { }

  private getSpecialManager() {
    let Manager

    const checkLtspcPay = ({ unifiedPayParams, paymentCode }) => {
      const res = {
        isLtspcJumpPciPay: false,
        isFrontTokenPay: false,
        isFrontPay: false,
      }
      if (!this.bsUtils?.checkLtspcPaymentMethod?.(paymentCode)) return res
      if (unifiedPayParams.tokenId || unifiedPayParams?.c2pFlag) {
        return Object.assign({}, res, { isFrontTokenPay: true })
      }
      if (unifiedPayParams.routeId) {
        return Object.assign({}, res, { isFrontPay: true })
      }
      return Object.assign({}, res, { isLtspcJumpPciPay: true })
    }

    const { payData } = this.managerConfig || {}
    const { isFrontPay, isFrontTokenPay, isLtspcJumpPciPay } = checkLtspcPay({ paymentCode: payData.paymentCode, unifiedPayParams: payData.unifiedPayParams })
    if (isLtspcJumpPciPay) Manager = LtspcPayManager
    if (isFrontTokenPay) Manager = FrontTokenPayManager
    if (isFrontPay) Manager = FrontPayManager

    const appConfigs = useAppConfigs()

    const platform = appConfigs.$envs.project

    debuggerLog('getSpecialManager==paymentCode===', payData.paymentCode, platform)

    if (SelfBuiltPagePayManager.selfBuiltPagePaymentCodes.includes(payData.paymentCode)) {
      Manager = SelfBuiltPagePayManager
    }

    if (GooglePayManager.googlePayCodeList.includes(payData.paymentCode)) {
      Manager = GooglePayManager
    }

    if (ApplePayManager.isApplePay(payData.paymentCode)) {
      Manager = ApplePayManager
    }

    if (platform === 'pc' && BlikPayDialogManager.paymentMethods.includes(payData.paymentCode)) {
      Manager = BlikPayDialogManager
    }

    debuggerLog('getSpecialManager', Manager)

    return Manager || NormalPayManager
  }

  private initManager(): CheckstandManagerInstance {
    const { paymentCode } = this.managerConfig?.payData || {}
    debuggerLog('initManager', this.managerConfig)
    const SpecialManager = this.getSpecialManager()

    let Manager
    switch (paymentCode) {
      case PaidyPayManager.PAYMENT_CODE:
        Manager = PaidyPayManager
        break
      case PaypalGaManager.PAYMENT_CODE:
        Manager = PaypalGaManager
        break
      case PaypalVenmoManager.PAYMENT_CODE:
        Manager = PaypalVenmoManager
        break
      case AfterPayCashAppManager.PAYMENT_CODE:
        Manager = AfterPayCashAppManager
        break
      case KlarnaInlineManager.PAYMENT_CODE:
        Manager = KlarnaInlineManager
        break
      case CodPayManager.PAYMENT_CODE:
        Manager = CodPayManager
        break
      default:
        Manager = SpecialManager
        break
    }
    return new Manager(this.managerConfig)
  }

  public static getInstance(): CheckstandManager {
    if (!this.instance) {
      this.instance = new CheckstandManager()
    }
    return this.instance
  }

  public static setup(managerConfig: CheckstandManagerConstructor) {
    const instance = this.getInstance()

    instance.managerConfig = managerConfig
    instance.bsUtils = managerConfig.bsUtils
    instance.bsConfig = managerConfig.bsPayConfig

    instance.managerInstance = instance.initManager()

    instance.managerInstance?.bindingMethods?.({
      paymentLoadingAction: managerConfig.paymentLoadingAction,
    })

    CreateNewWindow.bindCompleteCb(instance.managerInstance?.newWindowCompleteCb.bind(instance.managerInstance))

    return instance
  }

  // 支付
  public static async pay() {
    const instance = this.getInstance()
    debuggerLog('支付===', instance, instance.managerConfig)

    if (typeof window !== 'undefined') {
      window.BS_Checkstand = this
      window.BS_Checkstand_Instance = instance
    }

    return instance.managerInstance?.createPayment?.()
  }
}

