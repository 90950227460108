export const DEFAULT_CARD_NETWORKS = [
  'AMEX',
  'DISCOVER',
  'JCB',
  'MASTERCARD',
  'VISA',
]

export const DEFAULT_AUTH_METHODS = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

export const GOOGLE_PAY_CHANNEL = ['worldpay-googlepay', 'adyen-googlepay', 'nuvei-googlepay', 'routepay-googlepay']
