/* global AdyenCheckout */
import { loadChannelSdk } from '.'
import { debuggerLog } from '../utils'

export default class Adyen3ds {
  completeHandle: any = function () {}

  errorHandle: any = function () {}

  env = 'live'

  isValid = false
  paymentData = {}
  inChallenge = false //是否正在挑战
  checkout: any = null

  constructor(
    options: {
      completeHandle: () => void;
      errorHandle: (err?: any) => void;
      originKey: string;
      env: string;
  } = {
      completeHandle: function () {},
      errorHandle: function () {},
      originKey: '',
      env: 'live',
    },
  ) {
    if (typeof window === 'undefined') return
    this.env = options.env || 'live'
    this.completeHandle = options.completeHandle
    this.errorHandle = options.errorHandle
    this.initSDK().then(() => {
      this.initAdyen(options.originKey)
    })
  }

  async initSDK() {
    return await loadChannelSdk.initAdyenSdk()
  }

  initAdyen(originKey) {
    //自定义语言
    const brands = ['amex', 'jcb', 'diners', 'discover', 'maestro', 'visa', 'mc']
    const paymentMethodsResponse = {
      groups: [
        {
          name: 'Credit Card',
          types: brands,
        },
      ],
      paymentMethods: [
        {
          brands: brands,
          details: [
            { key: 'number', type: 'text' },
            { key: 'expiryMonth', type: 'text' },
            { key: 'expiryYear', type: 'text' },
            { key: 'cvc', type: 'text' },
            { key: 'holderName', optional: true, type: 'text' },
          ],
          name: 'Credit Card',
          type: 'scheme',
        },
      ],
    }

    const configuration = {
      locale: 'en-GB',
      environment: this.env === 'test' ? 'test' : 'live',
      originKey,
      paymentMethodsResponse,
      styles: {
        base: {},
        error: {},
        placeholder: {},
        validated: {},
      },
      placeholders: {},

      onChange: (state) => {
        this.paymentData = (state.data && state.data.paymentMethod) || {}
        this.isValid = state.isValid
      },
    }
    // @ts-ignore
    if (typeof AdyenCheckout !== 'undefined') {
      // @ts-ignore
      this.checkout = new AdyenCheckout(configuration)
    }
  }

  init3DSIS({
    ftoken,
    mountId,
  }) {
    debuggerLog('init3DSIS--ftoken--', ftoken, mountId)
    if (!this.checkout) return
    this.checkout
      .create('threeDS2DeviceFingerprint', {
        fingerprintToken: ftoken,
        onComplete: res => {
          // skdInitTimeAirReport({ billno })
          debuggerLog('init3DSIS--complete: ', res)
          this.goComplete(res, true)
        },
        onError: (err) => {
          debuggerLog('error: ', err)
          this.errorHandle({ type: 'init3DSIS', err })
        },
        size: '05',
      })
      .mount(mountId)
  }

  initChallenge({
    ctoken,
    mountId,
  }) {
    if (!this.checkout) return
    debuggerLog('initChallenge--ctoken--', ctoken, mountId)
    setTimeout(() => {
      this.checkout
        .create('threeDS2Challenge', {
          challengeToken: ctoken,
          onComplete: res => {
            this.inChallenge = false
            this.goComplete(res, false)
          },
          onError: err => {
            debuggerLog('initChallenge-err: ', err)
            this.errorHandle({ type: 'initChallenge', err })
          },
          size: '05', // Defaults to '01'. Can be set to '02', '03', '04' or '05'.
        })
        .mount(mountId)
    }, 300)
  }

  goComplete(res, type) {
    // window.ocpEventCenter?.$emit?.('show-process-toast', true)
    const step = type ? 'IdentifyShopper' : 'ChallengeShopper'
    const result = type
      ? res.data.details['threeds2.fingerprint']
      : res.data.details['threeds2.challengeResult']

    this.completeHandle({
      step,
      verificationResult: result,
      independThreedsVerifyInfo: JSON.stringify(res || {}),
    })
  }
}
